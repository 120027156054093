import React from 'react'
import './PopUp.sass'
import Backdrop from './Backdrop/Backdrop'
import Icon from '../Icon/Icon'
import Media from 'react-media'
import useScrollLock from '../../hooks/useScrollLock'

function PopUp({
  show,
  close,
  transparentBackdrop,
  icon,
  title,
  className,
  children,
}) {
  // useScrollLock()

  const pop = (
    <div className={['PopUp', ...(className ? [className] : [])].join(' ')}>
      <div className="PopUp-Header">
        <div className="Heder-Title">
          {icon && <Icon name={icon} />}
          <h3>{title}</h3>
        </div>
        {close && <Icon onClick={close} name="times" />}
      </div>
      <div className="PopUp-Content">{children}</div>
    </div>
  )

  return (
    <>
      <Media
        query="(min-width: 1367px)"
        render={() => (
          <Backdrop
            transparentBackdrop={transparentBackdrop}
            show={show}
            close={!close ? () => null : close}
          >
            {pop}
          </Backdrop>
        )}
      />
      <Media query="(max-width: 1366px)" render={() => pop} />
    </>
  )
}

export default PopUp
