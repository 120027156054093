import React from 'react'
import Button from '../../../../ui/Button/Button'
import './VikupRequest.sass'
import img1 from './assets/img1.svg'
import img2 from './assets/img2.svg'
import img3 from './assets/img3.svg'
import img4 from './assets/img4.svg'
import img5 from './assets/img5.svg'
import img6 from './assets/img6.svg'
import Calc from './Calc/Calc'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'

function VikupRequest() {
  const { setShowContactUs } = useContext(AuthContext)
  return (
    <div id="calc" className="VikupRequest">
      <div className="VikupRequest-Container DefaultContainer">
        <h2>Сколько стоит ваша недвижимость?</h2>
        <div className="VikupRequest-FormContainer">
          <div className="TextForm">
            <h2>Точная оценка стоимости с выездом специалиста</h2>
            <p>Закажите выезд специалиста по недвижимости для точной оценки</p>
            <Button
              theme="primary"
              title="Заказать выезд специалиста"
              size={48}
              icon="phone"
              iconPosition="left"
              iconWeight="solid"
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Заказать выезд специалиста',
                  emailSubject: '❗Запрос на выезд специалиста',
                  messageId: 6,
                })
              }
            />
            <h2 style={{ marginTop: '24px' }}>
              Мы выкупаем все типы недвижимости
            </h2>
            <EstateTiles />
          </div>
          <Calc />
        </div>
      </div>
    </div>
  )
}

function EstateTiles() {
  return (
    <div className="EstateTiles">
      <div className="EstateTile">
        <img src={img1} alt="Квартиры" />
        <span className="Title">Квартиры</span>
      </div>
      <div className="EstateTile">
        <img src={img2} alt="Комнаты" />
        <span className="Title">Комнаты</span>
      </div>
      <div className="EstateTile">
        <img src={img3} alt="Коммерческая недвижимость" />
        <span className="Title">Коммерческая недвижимость</span>
      </div>
      <div className="EstateTile">
        <img src={img4} alt="Доли" />
        <span className="Title">Доли</span>
      </div>
      <div className="EstateTile">
        <img src={img5} alt="Земельные участки, дома" />
        <span className="Title">Земельные участки, дома</span>
      </div>
      <div className="EstateTile">
        <img src={img6} alt="Гараж" />
        <span className="Title">Гараж</span>
      </div>
    </div>
  )
}

export default VikupRequest
