import React, { useEffect, useState } from 'react'
import Icon from '../../ui/Icon/Icon'
import './FeedsCarousel.sass'
import FeedTile from './FeedTile/FeedTile'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import Carousel from '../../ui/Carousel/Carousel'
import PopUp from '../../ui/PopUp/PopUp'
import Button from '../../ui/Button/Button'
import parse from 'html-react-parser'
import promiseAllValues from '../../utils/promiseAllValues'
import getDoc from '../../utils/db/getDoc'
import { useContext } from 'react'
import { AuthContext } from '../../App'

function FeedsCarousel({ className }) {
  const [feeds, setFeeds] = useState(null)
  const [showPopUp, setShowPopUp] = useState(false)
  const { setShowContactUs } = useContext(AuthContext)

  useEffect(() => {
    getCollection({
      path: 'accounts/mcun/feeds',
      docIdName: 'feedId',
    }).then((result) => {
      setFeeds(result)
    })
  }, [])

  return (
    <div className={`FeedsCarousel ${className}`}>
      <div className="FeedsCarousel-Container DefaultContainer">
        <h2>Клиенты довольны нашими специалистами на 97%!</h2>
        <div className="RatingButtons">
          <a
            href="https://yandex.ru/maps/org/murmanskiy_tsentr_upravleniya_nedvizhimostyu/1834677798/reviews/?ll=33.091743%2C68.967039&z=14"
            target="blank"
            className="RatingButton Yandex"
          >
            <Icon name="yandex" weight="brand" />
            <span className="Caption-Medium">Рейтинг:</span>
            <span className="Caption-Regular">4.9</span>
          </a>
          <a
            href="https://www.google.com/search?sca_esv=562779362&tbs=lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2!3sIAE,lf:1,lf_ui:14&tbm=lcl&sxsrf=AB5stBg7XGp2hKzqOUcCvU0RLqW9MlNXqQ:1693938278882&q=мцун&rflfq=1&num=10&rllag=68969151,33084144,376&ved=2ahUKEwin8Ijri5SBAxXJKBAIHXtDDQ0QtgN6BAgYEAY&rlst=f#rlfi=hd:;si:13454619773820931216,l,CgjQvNGG0YPQvUiZv9Dc4quAgAhaFBAAGAAiCNC80YbRg9C9KgQIAhAAkgEScmVhbF9lc3RhdGVfYWdlbmN5qgExEAEyHxABIhu96AVqU97m2GP97RbxAEwHElMkC6tBIPbgkz8yDBACIgjQvNGG0YPQvQ;mv:[[68.9714391,33.092572499999996],[68.9668642,33.075716]]"
            className="RatingButton Google"
          >
            <Icon name="google" weight="brand" />
            <span className="Caption-Medium">Рейтинг:</span>
            <span className="Caption-Regular">5.0</span>
          </a>
        </div>
        {!isEmpty(showPopUp) && (
          <PopUp
            show={showPopUp}
            title={showPopUp.author}
            close={() => setShowPopUp(false)}
          >
            <div className="DescriptionContent">{parse(showPopUp.text)}</div>
            <Button
              className="CloseFeedDescription"
              theme="primary-white"
              title="Закрыть"
              type="button"
              onClick={() => setShowPopUp(false)}
            />
          </PopUp>
        )}
        <div className="FeedsCarousel-Feeds">
          <div className="FeedContainer">
            {!isEmpty(feeds) && (
              <Carousel
                element="Feeds-Carousel"
                options={{
                  type: 'carousel',
                  gap: 24,
                  perView: 3,
                  hoverpause: true,
                  // autoplay: 5000,
                  // useDots: true,
                  useArrows: true,
                  breakpoints: {
                    1200: { perView: 3 },
                    992: { perView: 2 },
                    576: { perView: 1 },
                  },
                }}
              >
                {feeds.map((f) => (
                  <FeedTile key={f.feedId} {...f} setShowPopUp={setShowPopUp} />
                ))}
              </Carousel>
            )}
            <div className="LeaveFeedBlock">
              <h3>Уже были нашим клиентом?</h3>
              <p className="Body-Regular-1">
                Нам важно знать, что Вы думаете о качестве наших услуг. Будем
                рады Вашему отзыву
              </p>
              <Button
                theme="primary"
                title="Оставить отзыв"
                type="button"
                icon="envelope"
                iconWeight="solid"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Оставить отзыв',
                    emailSubject: '💬 Отзыв с сайта',
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedsCarousel
