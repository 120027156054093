import React from 'react'
import './VikupBenefits.sass'
import icon from './icon.svg'

function VikupBenefits() {
  return (
    <div className="VikupBenefits">
      <div className="VikupBenefits-Container DefaultContainer">
        <h2>Почему это выгодно?</h2>
        <p className="Body-Regular-1">
          Нет необходимости тратить свое время и деньги на продажу вашей
          недвижимости ради сомнительного результата. Всю работу по реализации
          объекта мы берем на себя:
        </p>

        <div className="Benefits-TilesContainer">
          <BenefitTile
            icon=""
            title="Бесплатная оценка недвижимости"
            description="Рассчитаем стоимость вашей недвижимости онлайн и скорректируем её после выезда специалиста. Это бесплатно."
          />
          <BenefitTile
            icon=""
            title="Предпродажная подготовка"
            description="Дадим полный пакет рекомендаций - что надо обновить в квартире, чтобы с минимальными вложениями привести ее в хороший вид"
          />
          <BenefitTile
            icon=""
            title="Профессиональная фотосессия квартиры"
            description="Сделаем качественные фото которые подчеркнут преимущества вашей квартиры и привлекут еще больше покупателей"
          />
          <BenefitTile
            icon=""
            title="Внутренняя продажа"
            description="В нашей базе более 2 тыс реальных покупателей, которые уже находятся в поиске подходящих объектов недвижимости. Ваше предложение будет первым, что они увидят в ежедневных сводках."
          />
          <BenefitTile
            icon=""
            title="Лучшая презентация"
            description="Выставим объект недвижимости привлекательным для покупателей на всех интернет площадках и базах по поиску недвижимости, проведем презентацию по авторской технологии, которая в разы эффективнее типичного показа"
          />
          <BenefitTile
            icon=""
            title="Срочный выкуп"
            description="Сами выкупим вашу недвижимость уже сегодня, если у вас нет времени ждать."
          />
        </div>
      </div>
    </div>
  )
}

function BenefitTile({ title, description }) {
  return (
    <div className="BenefitTile">
      <div className="BenefitIcon">
        <img src={icon} alt={title} />
      </div>
      <h3 className="Title">{title}</h3>
      <p className="Body-Regular-1 Description">{description}</p>
    </div>
  )
}

export default VikupBenefits
