import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'
import { functions } from '../../../../config/firebase'
import Button from '../../../../ui/Button/Button'
import PopUp from '../../../../ui/PopUp/PopUp'
import StatusMessage, {
  useStatusMessage,
} from '../../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../../utils/forms/createForm'
import getFormValues from '../../../../utils/forms/getFormValues'
import FormRender from '../../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'
import isFormValid from '../../../../utils/forms/validation/isFormValid'
import './EduCheckout.sass'
import ellipse from './ellipse.svg'
import pattern from './pattern.svg'
import sendTgMessage from '../../../../utils/sendTgMessage'

function EduCheckout() {
  const { city } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: new EduCheckoutForm(),
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [showContactUs, setShowContactUs] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    // const func = functions.httpsCallable('emailSender')
    // func({
    //   subject: `Запись на обучение [${city.title}]`,
    //   to: 'mcun51@mail.ru',
    //   html: `
    //   Имя: ${values.userName}<br/>
    //   Номер телефона: ${values.phone.value}<br/>`,
    // })
    sendTgMessage({
      telegramChatId: '-1002004320977',
      threadId: 18,
      msg: `
👨‍🎓 Записаться на обучение
Имя: ${values.userName}
Номер телефона: ${values.phone.value}
      `,
    }).then((result) => {
      if (result.data.ok) {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Спасибо за обращение. Наш менеджер свяжется с Вами в ближайшее время!',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new EduCheckoutForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="EduCheckout">
      {showContactUs && (
        <PopUp
          show
          close={() => setShowContactUs(false)}
          title={'Записаться на обучение'}
          className="EduCheckout-PopUp"
        >
          <p className="Body-Bold-2">Дайте ответ на 7 простых вопросов:</p>
          <ul className="PopUp-List">
            <li>Готовы ли ВЫ ездить в отпуск каждые три месяца?</li>
            <li>Готовы ли Вы совершать дорогостоящие покупки?</li>
            <li>Готовы ли Вы сами планировать свой рабочий день?</li>
            <li>Готовы ли Вы к новым знаниям?</li>
            <li>Готовы ли Вы к новым знакомствам?</li>
            <li>Готовы ли Вы быть успешным?</li>
            <li>Готовы ли Вы пройти БЕСПЛАТНОЕ обучение?</li>
          </ul>
          <p className="Body-Bold-2">Если вы ответили ДА, тогда Вам к нам!</p>
          <div className="EduForm">
            {statusMessage.show && (
              <StatusMessage
                className="Site-StatusMessage"
                type={statusMessage.type}
                message={statusMessage.message}
              />
            )}
            <FormRender
              sections={[{ fields: ['userName', 'phone'] }]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
            <Button
              title="ЗАПИСАТЬСЯ НА ОБУЧЕНИЕ"
              theme="primary"
              icon="clipboard-list-check"
              onClick={onSubmit}
              isLoading={isLoading}
            />
          </div>
        </PopUp>
      )}

      <div
        className="Pattern"
        style={{ backgroundImage: `url(${pattern})` }}
      ></div>
      <div className="EduCheckout-Container DefaultContainer">
        <h1>Записаться на обучение</h1>
        <p className="Description">
          Заполни анкету онлайн и запишись на обучение в ближайшую группу. Старт
          курса в феврале
        </p>
        <Button
          title="ЗАПИСАТЬСЯ НА ОБУЧЕНИЕ"
          theme="primary"
          icon="clipboard-list-check"
          onClick={() =>
            setShowContactUs({
              show: true,
              title: 'Записаться на обучение',
              emailSubject: '',
            })
          }
        />
      </div>
    </div>
  )
}

class EduCheckoutForm {
  constructor() {
    this.userName = {
      field: {
        fieldId: 'userName',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        placeholder: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default EduCheckout
