import { differenceInDays, format } from 'date-fns'
import uk from 'date-fns/locale/ru'

const formatDateAsMonthNameAndYear = (date) =>
  format(date, 'LLLL, yyyy', { locale: uk })
const formatDateAsDayMontYearHHMM = (date) =>
  format(date, 'dd MMMM yyyy в HH:mm', { locale: uk })
const formatDateAsDayMonthYear = (date) =>
  format(date, 'dd.LL.yyyy', { locale: uk })
const formatDateAsDayMonthNameYear = (date) =>
  format(date, 'dd LLLL yyyy', { locale: uk })
const formatDateAsMonthNameYear = (date) =>
  format(date, 'LLLL yyyy', { locale: uk })
const formatDateAsMonthName = (date) => format(date, 'LLLL', { locale: uk })
const formatDateAsYYYYMMDD = (date) =>
  format(date, 'yyyy-LL-dd', { locale: uk })
const formatDateAsHHMM = (date) => format(date, 'HH:mm', { locale: uk })
const formatDateForDateChooser = (start, end, sameDay) =>
  `${format(start, 'dd', { locale: uk })} - ${format(end, 'dd MMMM', {
    locale: uk,
  })} (${
    differenceInDays(end, start) === 0
      ? 1
      : sameDay
      ? differenceInDays(end, start)
      : differenceInDays(end, start) + 1
  } дні прокату)`
const formatDateWithMonthForDateChooser = (start, end, sameDay) =>
  `${format(start, 'dd MMMM', { locale: uk })} - ${format(end, 'dd MMMM', {
    locale: uk,
  })} (${
    differenceInDays(end, start) === 0
      ? 1
      : sameDay
      ? differenceInDays(end, start)
      : differenceInDays(end, start) + 1
  } дні прокату)`
export {
  formatDateAsMonthNameAndYear,
  formatDateAsDayMontYearHHMM,
  formatDateAsDayMonthNameYear,
  formatDateAsMonthNameYear,
  formatDateAsMonthName,
  formatDateAsYYYYMMDD,
  formatDateAsHHMM,
  formatDateAsDayMonthYear,
  formatDateForDateChooser,
  formatDateWithMonthForDateChooser,
}
