import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import CityChooser from '../CityChooser/CityChooser'
import ContactUsPopUp from '../ContactUs/ContactUsPopUp'
import './Top.sass'
import { NavLink } from 'react-router-dom'
import getOnlyNumbers from '../../utils/forms/getOnlyNumbers'

function Top() {
  const { city, showContactUs, setShowContactUs } = useContext(AuthContext)

  return (
    <div className="Top">
      {showContactUs.show && <ContactUsPopUp />}
      <div className="Top-Container DefaultContainer">
        <div className="Top-Contact-Buttons">
          <CityChooser />
          <Button
            className="ContactPhoneNumber"
            type="link"
            path={`tel:+${getOnlyNumbers(city.contacts.phone)}`}
            theme="internal-link"
            size={40}
            icon="phone"
            iconWeight="solid"
            title={city.contacts.phone}
          />
          <Button
            theme="internal-link"
            size={40}
            icon="envelope"
            iconWeight="solid"
            title={city.contacts.email}
            className="Top-ContactsEmail"
          />
        </div>

        <div className="Top-Service-Buttons">
          {/* <Button
            theme="internal-link"
            size={40}
            icon="stream"
            iconWeight="solid"
            title="Сравнение"
          /> */}
          {/* <NavLink to="/whishlist">
            <Button
              theme="internal-link"
              size={40}
              icon="heart-circle"
              iconWeight="solid"
              title="Избранное"
            />
          </NavLink> */}
          <Button
            theme="internal-link"
            size={40}
            icon="plus-circle"
            iconWeight="solid"
            title="Разместить объявление"
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Запрос на размещение объявления',
                emailSubject: '📰 Запрос на размещение объявления',
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Top
