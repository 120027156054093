import axios from 'axios'
import config from '../config/telegram.json'

const sendTgMessage = ({ telegramChatId, threadId, msg }) => {
  const message = msg.replace(/([-_~>#+=|\(\){}.!])/g, '\\$1')
  console.log(
    '🚀 ~ file: sendTgMessage.js:9 ~ sendTgMessage ~ message:',
    message
  )
  return axios.post(
    `https://api.telegram.org/bot${config.telegram.token}/sendMessage?chat_id=${telegramChatId}&reply_to_message_id=${threadId}&parse_mode=MarkdownV2`,
    {
      text: message,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export default sendTgMessage
