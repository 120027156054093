import React from 'react'
import './Counters.sass'

function Counters() {
  const data = [
    { counter: 90, description: 'Застройщиков' },
    { counter: 270, description: 'Житловых комплексов' },
    { counter: 170, description: 'ЖК первой очереди' },
    { counter: 13000, description: 'Квартир' },
  ]
  return (
    <div className="Counters">
      <div className="Counters-Container DefaultContainer">
        {data.map((d) => (
          <CounterTile key={d.description} {...d} />
        ))}
      </div>
    </div>
  )
}

function CounterTile({ counter, description }) {
  return (
    <div className="CounterTile">
      <p className="Counter">{counter}</p>
      <p className="Description">{description}</p>
    </div>
  )
}

export default Counters
