import React, { useEffect } from 'react'
import './EstateOffer.sass'
import RieltorTile from '../../../../ui/RieltorTile/RieltorTile'
import EstateTile from '../../../../components/EstateTile/EstateTile'
import RieltorsBlock from './RieltorsBlock/RieltorsBlock'
import { useState } from 'react'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getCollection from '../../../../utils/db/getCollection'
import { isEmpty } from 'lodash'

function EstateOffer() {
  const [estate, setEstate] = useState(null)

  useEffect(() => {
    promiseAllValues({
      estate: getCollection({
        path: 'accounts/mcun/estate',
        docIdName: 'estateId',
        whereQueries: [
          {
            fieldPath: 'operation',
            op: '==',
            value: 'sell',
          },
          { fieldPath: 'isDeleted', op: '==', value: false },
        ],
        orderBy: { fieldPath: 'created.at', orderDir: 'desc' },
      }),
      estateTypes: getCollection({
        path: 'accounts/mcun/config/estate/types',
        docIdName: 'typeId',
      }),
    }).then((result) => {
      setEstate(result)
    })
  }, [])

  return (
    <div className="EstateOffer">
      <div className="EstateOffer-Container DefaultContainer">
        <div className="EstateBlock">
          <h2>Новые объявления</h2>
          <div className="EstateBlock-Container">
            {!isEmpty(estate) &&
              estate.estate
                .slice(0, 6)
                .map((e) => (
                  <EstateTile {...e} estateTypes={estate.estateTypes} />
                ))}
          </div>
        </div>
        <RieltorsBlock />
      </div>
    </div>
  )
}

export default EstateOffer
