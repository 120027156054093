import React from 'react'
import './SectionTitle.sass'

function SectionTitle({ title, theme = null }) {
  return (
    <h2 className={`SectionTitle ${theme ? 'SectionTitle_theme_white' : ''}`}>
      {title}
    </h2>
  )
}

export default SectionTitle
