import React from 'react'
import Button from '../../../../ui/Button/Button'
import Icon from '../../../../ui/Icon/Icon'
import './CourseInfo.sass'
import s1 from './assets/1.svg'
import s2 from './assets/2.svg'
import s3 from './assets/3.svg'
import s4 from './assets/4.svg'
import s5 from './assets/5.svg'
import sveta from './assets/sveta.png'
import toggleSpoiler from '../../../../utils/toggleSpoiler'

function CourseInfo({ onCallbackRequest }) {
  const benefits = [
    {
      title: '30 часов',
      description: 'Длительность курса',
      bg: '#EAFEEF',
      icon: s1,
    },
    {
      title: 'В офисе / онлайн',
      description: 'Формат обучения',
      bg: '#ECFCFF',
      icon: s2,
    },
    {
      title: 'Все желающие',
      description: 'В том числе без опыта работы',
      bg: '#FCF1FE',
      icon: s3,
    },
    {
      title: 'Оплачиваемая стажировка',
      description: 'С первого дня обучения. С опытным наставником',
      bg: '#EFF4FF',
      icon: s4,
    },
    {
      title: 'Сделка',
      description: 'Выход на высокие показатели по доходам в первые месяцы',
      bg: '#FFFDD4',
      icon: s5,
    },
  ]

  const program = [
    {
      title: 'Введение в профессию',
      content: [
        'Кто такой современный риелтор',
        'Его роль в сделке и должностные инструкции.',
      ],
    },
    {
      title: 'Обучение основам деятельности в сфере продаж',
      content: [
        'Знакомство с клиентом',
        'Установление доверительных отношений',
        'Выявления потребностей',
        'Презентация',
        'Работа с возражениями',
        'Закрытие сделки',
      ],
    },
    {
      title: 'Технологии поиска клиентов',
      content: [
        'Методы поиска клиентов',
        'Квалификация клиентов',
        'Отработка входящих заявок',
      ],
    },
    {
      title:
        'Ценообразование. Методы определения стоимости недвижимости. Ликвидность.',
      content: [
        'Конъюнктурный анализ цен на рынке недвижимости',
        'Определение минимальной-максимальной цены недвижимости путем сравнительного анализа конкурентных предложений',
        'Определение ликвидности и спроса на предложение',
      ],
    },
    {
      title: 'Документооборот в сделках с недвижимостью',
      content: [
        'Изучение документов, их форм и сроков изготовления',
        'Формирование необходимого пакета документов по сделкам',
      ],
    },
    {
      title: 'Правовые основы в сфере недвижимости',
      content: [
        'Основные статьи ГК РФ применительно к сделкам с недвижимостью',
        'Их применение, судебная практика',
      ],
    },
    {
      title: 'Изучение технических характеристик, планировок домов',
      content: [
        'Проекты домов, этажность, материал стен и перекрытий, года постройки и планировки квартир',
      ],
    },
    {
      title: 'Ипотека и особенности предоставления ипотечного продукта',
      content: [
        'Что такое ипотека, виды ипотечных программ',
        'Какие банки предоставляют и на каких условиях',
        'Требования к Заемщикам для предоставления ипотеки',
        'Страхование при ипотечных сделках',
      ],
    },
    {
      title: 'Презентация и технология успешных продаж',
      content: [
        'Презентации оказываемых услуг',
        'Презентация компании и агента',
        'Работа в ЦРМ системе',
        'Тренинги, мастер классы и обучение по новым программам',
      ],
    },
  ]

  return (
    <div className="Edu-CourseInfo">
      <div className="CourseInfo-Container DefaultContainer">
        <h2>Учебный курс “Навыки профессионального риэлтора”</h2>
        <h3 className="CourseInfo-Description">
          Для желающих освоить новую профессию
        </h3>
        <div className="Course-InfoBenefits">
          {benefits.map((b) => (
            <InfoBenefit {...b} />
          ))}
        </div>
        <h2>Программа курса</h2>
        <div className="Course-Program">
          <div className="Program-Image">
            <img src={sveta} alt="Программа курса" />
          </div>
          <div className="Program-Body">
            {program.map((p) => (
              <ProgramTile {...p} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function InfoBenefit({ title, description, icon, bg }) {
  return (
    <div className="InfoBenefit" style={{ background: bg }}>
      <div className="Title">
        <img src={icon} alt={title} className="Img-Icon" />
        <h2>{title}</h2>
      </div>
      <p className="Description">
        <h3>{description}</h3>
      </p>
    </div>
  )
}

function ProgramTile({ title, content }) {
  return (
    <div className="ProgramTile">
      <div className="ProgramTile-Header" onClick={toggleSpoiler}>
        <p className="ProgramTile-Check">
          <Icon name="angle-right" weight="solid" />
        </p>
        <h3>{title}</h3>
      </div>
      <div className="ProgramTile-Body">
        <ul className="Content" style={{ listStyle: 'disc' }}>
          {content.map((c) => (
            <li>{c}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CourseInfo
