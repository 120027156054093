import React from 'react'
import Icon from '../../Icon/Icon'
import './MapMarker.sass'

function MapMarker() {
  return (
    <div className="MapMarker">
      <Icon name="rings-wedding" weight="solid" />
    </div>
  )
}

export default MapMarker
