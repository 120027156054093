import React from 'react'
import './NovostroyBenefits.sass'
import i1 from './assets/i1.svg'
import i2 from './assets/i2.svg'
import i3 from './assets/i3.svg'
import i4 from './assets/i4.svg'
import i5 from './assets/i5.svg'
import i6 from './assets/i6.svg'

function NovostroyBenefits() {
  return (
    <div className="NovostroyBenefits">
      <div className="NovostroyBenefits-Container DefaultContainer">
        <h2>Почему с нами лучше?</h2>
        <div className="Benefits-TilesContainer">
          <BenefitTile
            icon={i1}
            title="Экономия времени"
            description="Мы обладаем самой большой базой новостроек в Воронеже. Предложим все интересные варианты, соответствующие Вашему запросу."
          />
          <BenefitTile
            icon={i2}
            title="Офис в Вронеже"
            description="Специалисты из нашего офиса в Воронеже с радостью организуют показ всех интересующих Вас объектов."
          />
          <BenefitTile
            icon={i3}
            title="Большой выбор"
            description="Мы работаем с застройщиками с самой высокой репутацией. В нашей базе более 1300 объектов недвижимости."
          />
          <BenefitTile
            icon={i4}
            title="Выгода"
            description="Официальные цены от застройщика и партнеров."
          />
          <BenefitTile
            icon={i5}
            title="Опыт"
            description="Наше агентство на рынке недвижимости уже более 16 лет."
          />
          <BenefitTile
            icon={i6}
            title="Безопасность"
            description="Берем на себя все юридические вопросы, чтобы сделка была комфортной и максимально прозрачной"
          />
        </div>
      </div>
    </div>
  )
}

function BenefitTile({ icon, title, description }) {
  return (
    <div className="BenefitTile">
      <div className="BenefitIcon">
        <img src={icon} alt={title} />
      </div>
      <h3 className="Title">{title}</h3>
      <p className="Body-Regular-1 Description">{description}</p>
    </div>
  )
}

export default NovostroyBenefits
