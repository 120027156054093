import React from 'react'
import AdBanner from '../../components/AdBanner/AdBanner'
import EstateCarousel from '../../components/EstateCarousel/EstateCarousel'
import EstateTile from '../../components/EstateTile/EstateTile'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import Top from '../../components/Top/Top'
import Button from '../../ui/Button/Button'
import VikupBenefits from './components/VikupBenefits/VikupBenefits'
import ToRentRequest from './components/ToRentRequest/ToRentRequest'
import './ToRent.sass'
import bg from './toRentHeader.jpg'

function ToRent() {
  return (
    <div className="ToRent">
      <Top />
      <Nav />
      <div className="Page-SectionsContainer">
        <Header
          className={'ToRent-Header'}
          backgroundSrc={bg}
          title={
            <h1 className="ToRent-Offer-Title">Сдать квартиру в Мурманске</h1>
          }
          description={
            <h3 className="ToRent-Offer-Description">
              Поможем сдать квартиру выгодно, просто и безопасно
            </h3>
          }
          buttons={
            <>
              <Button
                theme="primary"
                title="Подать объявление"
                type="navhashlink"
                path="#podat-obyavlenie-online"
              />
            </>
          }
        />
        <ToRentRequest />
        <VikupBenefits />
        <RequestConsultation />
        {/* <FeedsCarousel className="VikupFeeds" /> */}
        {/* <AdBanner /> */}
        <Footer />
      </div>
    </div>
  )
}

export default ToRent
