import React from 'react'
import InfoLine from '../../../../ui/InfoLine/InfoLine'
import './Internship.sass'
import s1 from './intership.jpg'

function Internship() {
  return (
    <div className="Internship">
      <div className="Internship-Container DefaultContainer">
        <h2>Оплачиваемая стажировка это</h2>
        <div className="Intership-Body">
          <div className="Text">
            <p>
              25-30 часов учебного интенсива с погружением в риелторское дело;
              <br />
              Азы от профессиональных специалистов, обучение по уникальным
              программам и тренингам, таким как: «Риелторская деятельность от А
              до Я», «НЕТ возражениям» и многие другие;
              <br />
              Гарантируемый процент по сделкам Всем обучающимся на этапе
              стажировки.
            </p>
            <h3>Основные преимущества</h3>
            <div className="Ticks">
              <InfoLine icon="check" title="Наставник 24/7" />
              <InfoLine
                icon="check"
                title="Заработок с первых дней стажировки"
              />
              <InfoLine
                icon="check"
                title="Возможность стабильного оклада (рассматривается персонально с каждым стажером)"
              />
              <InfoLine icon="check" title="Индивидуальный подход к каждому" />
              <InfoLine
                icon="check"
                title="Доступ к закрытым базам данных и эксклюзивным заявкам"
              />
              <InfoLine
                icon="check"
                title="Отсутствие ограничений в “выкупе недвижимости”. Да у нас выкупают даже стажеры."
              />
              <InfoLine
                icon="check"
                title="Комфортный офис в центре города с предоставлением рабочего места"
              />
              <InfoLine
                icon="check"
                title="Возможность карьерного роста с повышенными процентами по сделке"
              />
            </div>
          </div>
          <img src={s1} alt="Оплачиваемая стажировка" className="Image" />
        </div>
      </div>
    </div>
  )
}

export default Internship
