import React, { useState, useContext } from 'react'
import Button from '../Button/Button'
import NotificationBlock from '../NotificationBlock/NotificationBlock'
import PopUp from '../PopUp/PopUp'
import './LocationNotificationPopUp.sass'
import { AuthContext } from '../../App'
import setDoc from '../../utils/db/setDoc'
import getDoc from '../../utils/db/getDoc'

function LocationNotificationPopUp() {
  const [isLoading, setIsLoading] = useState(false)
  const { cart, setLocation } = useContext(AuthContext)

  const clean = () => {
    setIsLoading(true)
    setDoc({ path: 'carts', docId: cart.id, data: { products: [] } }).catch(
      (e) => {
        console.log(
          '🚀 ~ file: LocationNotificationPopUp.jsx ~ line 19 ~ clean ~ e',
          e
        )
        setIsLoading(false)
      }
    )
  }
  const save = () => {
    const product = cart.products[0]
    setIsLoading(true)
    getDoc({
      path: 'cities',
      docId: product.locationId,
      docIdName: 'id',
    }).then((result) => {
      localStorage.setItem('location', result.id)
      setLocation(result)
    })
  }

  return (
    <PopUp
      show
      title="Зміна міста/курорту"
      icon="map-marker-exclamation"
      className="LocationNotificationPopUp"
    >
      <div className="LocationNotificationPopUp-Container">
        <p>
          У вашому кошику вже є спорядження з іншого міста/курорту. Для того,
          щоб оформити замовлення, необхідно обрати лише одне місто/курорт.
        </p>
        <div className="Buttons">
          <Button
            title="Очистити кошик та продовжити"
            size={40}
            onClick={clean}
            isLoading={isLoading}
          />
          <Button
            title="Відмінити"
            theme="internal-link"
            size={40}
            isLoading={isLoading}
            onClick={save}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default LocationNotificationPopUp
