import React from 'react'
import Button from '../../../../ui/Button/Button'
import './WhatULearn.sass'
import agent from './assets/agent.png'
import rating from './assets/rating.png'
import free from './assets/like.png'
import quality from './assets/badge.png'
import teacher from './assets/teacher.png'
import profits from './assets/profits.png'

function WhatULearn({ onCallbackRequest }) {
  const data = [
    {
      title: 'Получите новую профессию',
      description: `Или повысьте квалификацию.Выбор за вами`,
      icon: agent,
    },
    {
      title: 'Востребованные специальности',
      description: `Знания в сфере недвижимости можно применить в любом регионе РФ и полезны в жизни`,
      icon: rating,
    },
    {
      title: 'Бесплатное обучение',
      description: `От вас потребуется только желание`,
      icon: free,
    },
    {
      title: 'Качественное обучение',
      description: `Лекции и семинары проводят опытные преподаватели`,
      icon: quality,
    },
    {
      title: 'Система наставничества',
      description: `Первые шаги в риэлторской деятельности под присмотром опытного наставника`,
      icon: teacher,
    },
    {
      title: 'Высокие доходы',
      description: `Месячный доход в сфере недвижимости не ограничен`,
      icon: profits,
    },
  ]
  return (
    <div className="EduBenefits">
      <div className="EduBenefits-Container DefaultContainer">
        <h2>6 причин принять участие</h2>

        <div className="EduBenefits-TilesContainer">
          {data.map((d) => (
            <EduBenefitTile {...d} />
          ))}
        </div>
      </div>
    </div>
  )
}

function EduBenefitTile({ title, description, icon }) {
  return (
    <div className="EduBenefitTile">
      <div className="EduBenefitIcon">
        <img src={icon} alt={title} />
      </div>
      <h3 className="Title">{title}</h3>
      <p className="Body-Regular-1 Description">{description}</p>
    </div>
  )
}

export default WhatULearn
