import { find } from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../App'
import cities from '../../config/cities'
import useWindowSize from '../../hooks/useWindowSize'
import Button from '../../ui/Button/Button'
import PopUp from '../../ui/PopUp/PopUp'
import { getUrlQueryParams } from '../../utils/queries'
import './CityChooser.sass'

function CityChooser({ ...router }) {
  const { city, setCity } = useContext(AuthContext)
  const [showCityChooser, setShowCityChooser] = useState(false)
  const [cityPref, setCityPref] = useState(null)

  useEffect(() => {
    if (!localStorage.getItem('city')) {
      const params = getUrlQueryParams({
        history: router.history,
        params: ['city'],
      })
      const linkCity = params.city
      localStorage.setItem('city', linkCity)
      const defaultCity = find(cities, ['id', linkCity])
        ? find(cities, ['id', linkCity])
        : cities[0]
      setCity(defaultCity)
      setShowCityChooser(false)
      // if (window.screen.width >= 576) setShowCityChooser(true)
    }
  }, [])

  const onConfirm = () => {
    localStorage.setItem('city', cityPref.id)
    setCity(cityPref)
    setShowCityChooser(false)
  }
  const close = () => setShowCityChooser(false)

  return (
    <div className="CityChooser">
      <Button
        theme="internal-link"
        size={40}
        icon="map-marker-alt"
        iconWeight="solid"
        title={city.title}
        className="ChooserLink"
        onClick={() => setShowCityChooser(true)}
      />
      {showCityChooser && (
        <PopUp
          show
          close={close}
          title="Выбор города"
          className="CityChooserPopUp"
        >
          <div className="Cities-Container">
            {cities.map((c) => (
              <CityTile
                {...c}
                onClick={() => setCityPref(c)}
                isActive={cityPref && cityPref.id === c.id}
              />
            ))}
          </div>
          <div className="Buttons">
            <Button
              title="Сохранить"
              size={40}
              theme="primary"
              onClick={onConfirm}
            />
            <Button
              title="Отмена"
              size={40}
              theme="secondary"
              onClick={close}
            />
          </div>
        </PopUp>
      )}
    </div>
  )
}

const CityTile = ({ isActive = false, title, onClick }) => (
  <Button
    theme="secondary"
    size={40}
    title={title}
    className="CityTile"
    onClick={onClick}
    state={isActive ? 'active' : 'default'}
  />
)

export function defineDefaultCity() {
  const savedCity = localStorage.getItem('city')
  if (savedCity && find(cities, ['id', savedCity]))
    return find(cities, ['id', savedCity])
  else {
    const defaultCity = find(cities, ['isDefault', true])
    return defaultCity
  }
}

export default withRouter(CityChooser)
