import React, { useState } from 'react'
import './NovostroyCalc.sass'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import { createForm } from '../../../utils/forms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import FormRender from '../../../utils/forms/render/FormRender'
import Button from '../../../ui/Button/Button'
import calcPattern from './assets/calcPattern.svg'
import trianglePattern from './assets/trianglePattern.svg'
import publicIp from 'public-ip'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../utils/forms/getFormValues'
import sendTgMessage from '../../../utils/sendTgMessage'

function NovostroyCalc() {
  const [form, setForm] = useState(createForm({ formPattern: new CalcForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onCalcSubmit = async (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    // try {
    //   window.ym(96308489, 'reachGoal', 'request_' + city.id)
    // } catch (e) {
    //   console.log(e)
    // }

    const values = getFormValues({ form })

    const ip = await publicIp.v4()

    sendTgMessage({
      telegramChatId: '-1002004320977',
      threadId: 6,
      msg: `
‼️ Запрос на подбор квартиры в новостройке

Номер телефона: ${values.phone.value}
IP: ${ip}
      `,
    }).then((result) => {
      if (result.data.ok) {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Спасибо за предоставленные данные. В скором времени вы получите результаты расчетов!',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new CalcForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div id="calc" className="NovostroyCalc">
      <div className="NovostroyCalc-Container DefaultContainer">
        <h2>Подобрать квартиру</h2>
        <div className="NovostroyCalc-Calc">
          <div className="SelectionData">
            <div className="DataSection TwoCols">
              <p className="Title">Какую квартиру вы хотите купить?</p>
              <FormRender
                sections={[
                  {
                    fields: ['studio', 'f1', 'f2', 'f3', 'f4'],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
            <div className="DataSection OneCol">
              <p className="Title">Срок сдачи дома</p>
              <FormRender
                sections={[
                  {
                    fields: ['s1', 's2', 's3', 's4'],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
            <div className="DataSection ThreeCols">
              <p className="Title">В каком районе города?</p>
              <FormRender
                sections={[
                  {
                    fields: [
                      'r1',
                      'r2',
                      'r3',
                      'r4',
                      'r5',
                      'r6',
                      'r7',
                      'r8',
                      'r9',
                      'r10',
                      'r11',
                      'r12',
                      'r13',
                      'r14',
                      'r15',
                    ],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
          </div>
          <div
            className="CalcDesign"
            style={{
              backgroundImage: `url(${calcPattern}), url(${trianglePattern})`,
            }}
          >
            <h2 className="Title">Ваш контактный месенджер</h2>
            <p className="Description Body-Regular-2">
              Система пришлет подборку объектов, удовлетворяющих Ваш запрос в
              удобном формате в месенджер
            </p>
            <div className="MessangersBlock">
              <FormRender
                sections={[
                  {
                    fields: ['telega', 'what', 'viber', 'call'],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
            {statusMessage.show && (
              <StatusMessage
                className="Site-StatusMessage"
                type={statusMessage.type}
                message={statusMessage.message}
              />
            )}
            <div className="PhoneAndButton">
              <FormRender
                sections={[
                  {
                    fields: ['phone'],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
              <Button
                title="Получить подборку квартир"
                theme="primary"
                icon="calculator-alt"
                iconWeight="solid"
                size={48}
                className="Button_theme_primary_color_black"
                onClick={onCalcSubmit}
                isLoading={isLoading}
              />
              <p className="SmallDesc">
                Нажимая накнопку “Получить подборку квартир”, я принимаю условия
                Пользовательского соглашения и даю согласие на обработку своих
                персональных данных.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class CalcForm {
  constructor() {
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.studio = {
      field: {
        fieldId: 'studio',
        fieldType: 'checkbox',
        required: false,
        label: 'Студия',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.f1 = {
      field: {
        fieldId: 'f1',
        fieldType: 'checkbox',
        required: false,
        label: '1-комнатную',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.f2 = {
      field: {
        fieldId: 'f2',
        fieldType: 'checkbox',
        required: false,
        label: '2-комнатную',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.f3 = {
      field: {
        fieldId: 'f3',
        fieldType: 'checkbox',
        required: false,
        label: '3-комнатную',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.f4 = {
      field: {
        fieldId: 'f4',
        fieldType: 'checkbox',
        required: false,
        label: '4-комнатную',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.s1 = {
      field: {
        fieldId: 's1',
        fieldType: 'checkbox',
        required: false,
        label: 'Сдается в этом году',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.s2 = {
      field: {
        fieldId: 's2',
        fieldType: 'checkbox',
        required: false,
        label: 'Сдается через год',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.s3 = {
      field: {
        fieldId: 's3',
        fieldType: 'checkbox',
        required: false,
        label: 'Сдается через 2 года',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.s4 = {
      field: {
        fieldId: 's4',
        fieldType: 'checkbox',
        required: false,
        label: 'Дом сдан',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r1 = {
      field: {
        fieldId: 'r1',
        fieldType: 'checkbox',
        required: false,
        label: 'Любой район',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r2 = {
      field: {
        fieldId: 'r2',
        fieldType: 'checkbox',
        required: false,
        label: 'Железнодорожный',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r3 = {
      field: {
        fieldId: 'r3',
        fieldType: 'checkbox',
        required: false,
        label: 'Советский',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r4 = {
      field: {
        fieldId: 'r4',
        fieldType: 'checkbox',
        required: false,
        label: 'Советский',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r5 = {
      field: {
        fieldId: 'r5',
        fieldType: 'checkbox',
        required: false,
        label: 'Коминтерновский',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r6 = {
      field: {
        fieldId: 'r6',
        fieldType: 'checkbox',
        required: false,
        label: 'Ленинский',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r7 = {
      field: {
        fieldId: 'r7',
        fieldType: 'checkbox',
        required: false,
        label: 'Левобережный',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r8 = {
      field: {
        fieldId: 'r8',
        fieldType: 'checkbox',
        required: false,
        label: 'Центральный',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r9 = {
      field: {
        fieldId: 'r9',
        fieldType: 'checkbox',
        required: false,
        label: 'г. Семилуки',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r10 = {
      field: {
        fieldId: 'r10',
        fieldType: 'checkbox',
        required: false,
        label: 'с. Бабяково',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r11 = {
      field: {
        fieldId: 'r11',
        fieldType: 'checkbox',
        required: false,
        label: 'с. Новая Усмань',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r12 = {
      field: {
        fieldId: 'r12',
        fieldType: 'checkbox',
        required: false,
        label: 'с. Чертовицы',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r13 = {
      field: {
        fieldId: 'r13',
        fieldType: 'checkbox',
        required: false,
        label: 'с. Ямное',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r14 = {
      field: {
        fieldId: 'r14',
        fieldType: 'checkbox',
        required: false,
        label: 'п. Рамонь',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.r15 = {
      field: {
        fieldId: 'r10',
        fieldType: 'checkbox',
        required: false,
        label: 'п. Шилово',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.telega = {
      field: {
        fieldId: 'telega',
        fieldType: 'checkbox',
        required: false,
        label: 'Telegram',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.what = {
      field: {
        fieldId: 'what',
        fieldType: 'checkbox',
        required: false,
        label: 'WhatsApp',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.viber = {
      field: {
        fieldId: 'viber',
        fieldType: 'checkbox',
        required: false,
        label: 'Viber',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.call = {
      field: {
        fieldId: 'call',
        fieldType: 'checkbox',
        required: false,
        label: 'Звонок специалиста по недвижимости',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default NovostroyCalc
