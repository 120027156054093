import React from 'react'
import Icon from '../../../../ui/Icon/Icon'
import './Resume.sass'
import mcunTeam from './mcunTeam.png'
import tri from './tri.svg'

function Resume() {
  const data = [
    {
      title: 'Заработная плата',
      description: 'от 100 000 рублей',
      icon: 'credit-card',
    },
    {
      title: 'Местонахождение',
      description: 'г. Мурманск, Воронеж',
      icon: 'map-marker-alt',
    },
    {
      title: 'Формат работы',
      description: 'Очная работа в офисе компании',
      icon: 'keyboard',
    },
  ]
  return (
    <div className="Resume">
      <div className="Resume-Container DefaultContainer">
        <h2>Что я получу после обучения?</h2>
        <div className="Resume-Employee">
          <img src={mcunTeam} alt="Светлана" />
        </div>
        <div className="Resume-Bar">
          {data.map((d) => (
            <ResumeTile {...d} />
          ))}
        </div>
        <div className="Resume-Skills">
          <h2>Освоив профессиональные навыки я могу:</h2>
          <div className="Skills-Container">
            <ul>
              <li>Определять рыночную стоимость объекта недвижимости</li>
              <li>
                Анализировать и работать с особенностями рынка недвижимости
              </li>
              <li>Профессионально консультировать клиентов</li>
              <li>Презентовать объекты недвижимости</li>
            </ul>
            <ul>
              <li>Участвовать в переговорных процессах</li>
              <li>Анализировать пакет документов</li>
              <li>Разбираться в ипотечных программах</li>
              <li>Проводить сделки с недвижимостью любой сложности</li>
            </ul>
          </div>
          <div
            className="Decoration"
            style={{ backgroundImage: `url(${tri})` }}
          ></div>
        </div>
      </div>
    </div>
  )
}

function ResumeTile({ title, description, icon }) {
  return (
    <div className="ResumeTile">
      <Icon name={icon} />
      <div className="Content">
        <h3 className="Title">{title}</h3>
        <p className="Body-Regular-1 Description">{description}</p>
      </div>
    </div>
  )
}

export default Resume
