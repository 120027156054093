import React from 'react'
import './EstateWhishlist.sass'
import Top from '../../components/Top/Top'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import RieltorTile from '../../ui/RieltorTile/RieltorTile'
import Button from '../../ui/Button/Button'
import ImageCarousel from '../EstateObject/components/ImageCarousel/ImageCarousel'
import { NavHashLink } from 'react-router-hash-link'
import { NavLink } from 'react-router-dom'

function EstateWhishlist() {
  return (
    <div>
      <Top />
      <Nav />

      <div className="EstateWhishlist">
        <div className="DefaultContainer">
          <div className="EstateWhishlist-Container">
            <div className="EstateWhishlist-Header">
              <h2>Избранные объекты</h2>
            </div>
            <EstateBaseTile />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

function EstateBaseTile() {
  return (
    <NavLink to="/catalog-nedvizhimosti/id">
      <div className="EstateBaseTile">
        <div className="PhotoBlock">
          <ImageCarousel className={'EstateBaseCarousel'} thumbnails={3} />
        </div>
        <div className="DataBlock">
          <div className="BaseTitle">
            <h4>4х комнатная квартира, 42м2</h4>
            <div className="Controlls">
              <h2 className="Price">5 290 000 ₽</h2>
              <Button
                theme="secondary"
                icon="heart"
                iconWeight="solid"
                path="#calc"
              />
            </div>
          </div>
          <p className="Address">г. Мурманск, ул Советская д.5</p>
          <ul className="ParamBlock">
            <h4 className="Title">Об объекте</h4>
            <li className="ParamData">
              <span className="ParamTitle">Площадь</span>
              <span className="ParamDevider">&nbsp;</span>
              <span className="ParamValue">1</span>
            </li>
            <li className="ParamData">
              <span className="ParamTitle">Площадь</span>
              <span className="ParamDevider">&nbsp;</span>
              <span className="ParamValue">1</span>
            </li>
          </ul>
          <div className="DescriptionBlock">
            <h4>Описание</h4>
            <p className="Description">
              Продажа готового к въезду помещения от собственника - передача
              помещения и ключей в день сделки. Высококачественный ремонт,
              никаких вложений не требуется. Студия для личных целей или сдачи в
              аренду. Статус помещения - нежилое, прописка не предусмотрена. Не
              доля, выделенный кадастровый паспорт . В доме современная
              дизайнерская отделка холлов и входной группы. Видеонаблюдение,
              домофон, охрана, профессиональная управляющая компания с низкими
              тарифами. Низкая кадастровая стоимость помещения. Высокие потолки
              - 3 метра. Второй этаж и огороженная территория! Дом расположен в
              сложившемся спальном районе города, не промзона. Прямо перед
              подъездом городской парк с зонами отдыха. Во дворе супермаркет,
              школа, детский сад и поликлиника. В непосредственной близости
              метро Волжская - 5 минут пешком. Помещение имеет отдельный
              кадастровый номер, продается в собственность, полная стоимость в
              договоре, без обременений. По вашему желанию: - меблировка и декор
              под ключ профессиональным дизайнером; - услуги управляющей
              компании по сдаче в аренду. Это потенциальный арендный бизнес; -
              услуги в получении ипотеки по индивидуальным тарифам;
            </p>
          </div>
          <div className="ActionBlock">
            <RieltorTile />
            <Button
              theme="primary"
              title="Заказать звонок"
              type="navhashlink"
              icon="phone-alt"
              iconWeight="solid"
              path="#calc"
            />
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default EstateWhishlist
