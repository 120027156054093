import React from 'react'
import Carousel from '../../../../ui/Carousel/Carousel'
import './ImageCarousel.sass'

function ImageCarousel({ photos, className, thumbnails = 0 }) {
  return (
    <div className="ImageCarousel">
      <div className="ImageCarousel-Container">
        <Carousel
          element={className}
          options={{
            type: 'carousel',
            perView: 1,
            autoplay: 5000,
            useArrows: true,
          }}
        >
          {photos.map((p) => (
            <img key={p.publicUrl} src={p.publicUrl} />
          ))}
        </Carousel>
      </div>
      {thumbnails > 0 && (
        <div
          className="Thumbnails"
          style={{
            gridTemplateColumns: `repeat(${thumbnails}, 1fr)`,
          }}
        >
          {photos.slice(1, thumbnails + 1).map((p, i) => {
            if (i === thumbnails - 1) {
              return (
                <span className="LastPhoto">
                  <img src={p.publicUrl} alt="" />
                  <span className="TotalHiddenPhotos Body-Bold-2">
                    +{photos.length - thumbnails}
                  </span>
                </span>
              )
            } else {
              return <img src={p.publicUrl} alt="" />
            }
          })}
        </div>
      )}
    </div>
  )
}

export default ImageCarousel
