import React, { useEffect, useState } from 'react'
import './OnlineAd.sass'
import { NavHashLink } from 'react-router-hash-link'
import Button from '../../../../../ui/Button/Button'
import scrollWithOffset from '../../../../../ui/ScrollToTop/scrollWithOffset'
import { createForm } from '../../../../../utils/forms/createForm'
import FormRender from '../../../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../../../utils/forms/render/getFieldRenderObject'
import calcPattern from './assets/calcPattern.svg'
import trianglePattern from './assets/trianglePattern.svg'
import StatusMessage, {
  useStatusMessage,
} from '../../../../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../../../utils/forms/getFormValues'
import publicIp from 'public-ip'
import { functions } from '../../../../../config/firebase'
import sendTgMessage from '../../../../../utils/sendTgMessage'

function OnlineAd() {
  const [form, setForm] = useState(createForm({ formPattern: new CalcForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onCalcSubmit = async (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    try {
      window.ym(86916243, 'reachGoal', 'form')
    } catch (e) {
      console.log(e)
    }

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')
    const ip = await publicIp.v4()
    const text = `
      Тип недвижимости: ${values.estateType}<br/>
      Адрес: ${values.address}<br/>
      Этаж: ${values.floor}<br/>
      Площадь: ${values.space}<br/>
      Желаемая стоимость: ${values.price}<br/>
      Номер телефона: ${values.phone.value}<br/>
      IP: ${ip}<br/>
    `

    // sendSms({ message: text })

    // func({
    //   subject: 'Запрос на сдачу недвижимости в аренду',
    //   to: 'iseeblog1@gmail.com',
    //   html: text,
    // })
    sendTgMessage({
      telegramChatId: '-1002004320977',
      threadId: 18,
      msg: `
‼️ Запрос на подачу объявления об аренде недвижимости

Тип недвижимости: ${values.estateType}
Адрес: ${values.address}
Этаж: ${values.floor}
Площадь: ${values.space}
П Желаемая стоимость: ${values.price}
Номер телефона: ${values.phone.value}
IP: ${ip}
      `,
    }).then((result) => {
      if (result.data.ok) {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Спасибо за предоставленные данные. В скором времени вы получите результаты расчетов!',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new CalcForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div
      id="calc"
      className="OnlineAdForm"
      style={{
        backgroundImage: `url(${calcPattern}), url(${trianglePattern})`,
      }}
    >
      <h2>Подать объявление online</h2>
      <p className="Body-Regular-2">
        Укажите параметры вашей недвижимости для подачи объявления онлайн
      </p>

      <div className="Form">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[
            {
              fields: [
                'estateType',
                'address',
                'floor',
                'space',
                'price',
                'phone',
              ],
            },
          ]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="Buttons">
          <NavHashLink
            to="/#calc"
            smooth
            activeClassName="Site-NavLink_theme_active"
            scroll={(el) => scrollWithOffset(el, -64)}
          >
            <Button
              title="Подать объявление"
              theme="primary"
              icon="newspaper"
              iconWeight="solid"
              size={48}
              className="Button_theme_primary_color_black"
              onClick={onCalcSubmit}
              isLoading={isLoading}
            />
          </NavHashLink>
        </div>
        <p className="Caption-Regular Description">
          Нажимая накнопку “Рассчитать стоимость”, я принимаю условия
          Пользовательского соглашения и даю согласие на обработку своих
          персональных данных.
        </p>
      </div>
    </div>
  )
}

class CalcForm {
  constructor() {
    this.estateType = {
      field: {
        fieldId: 'estateType',
        fieldType: 'input',
        inputType: 'text',
        label: 'Тип недвижимости',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.address = {
      field: {
        fieldId: 'address',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес обьекта',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.floor = {
      field: {
        fieldId: 'floor',
        fieldType: 'input',
        inputType: 'number',
        label: 'Укажите этаж',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.space = {
      field: {
        fieldId: 'space',
        fieldType: 'input',
        inputType: 'text',
        label: 'Укажите площадь',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'input',
        inputType: 'text',
        label: 'Желаемая стоимость',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default OnlineAd
