import React from 'react'
import './EstateParamsData.sass'
import { find } from 'lodash'

function EstateParamsData({ paramsData, estateData }) {
  return (
    <ul className="EstateParamsData">
      {estateData.params
        .filter((p) => p.paramId !== 'ewVN5IRz0LYO0eB41YNJ')
        .map((p) => {
          const title = find(paramsData, ['paramId', p.paramId])

          return (
            <li className="ParamData">
              <span className="ParamTitle">{title.label}</span>
              <span className="ParamDevider">&nbsp;</span>
              <span className="ParamValue">{p.value}</span>
            </li>
          )
        })}
    </ul>
  )
}

export default EstateParamsData
