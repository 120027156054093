import React from 'react'
import Icon from '../../ui/Icon/Icon'
import './EstateTile.sass'
import img from './estateImage.jpg'
import { NavLink } from 'react-router-dom'
import { find } from 'lodash'

function EstateTile({
  estateId,
  address,
  photos,
  params,
  price,
  estateType,
  estateTypes,
  responsible,
}) {
  const rooms = find(params, ['paramId', 'YCi3Yr0CmxCeRb7qHIvz'])
  const type = find(estateTypes, ['typeId', estateType])

  const title =
    estateType === 'flat' ? `${type.label} (${rooms.value}-к)` : type.label

  const avatar = find(photos, ['isAvatar', true])
  const totalSpace = find(params, ['paramId', 'DvN83asYpGTY06ZMfIHN'])

  return (
    <NavLink to={`/catalog-nedvizhimosti/${estateId}`}>
      <div className="EstateTile">
        <div
          className="Image"
          style={{ backgroundImage: `url(${avatar.publicUrl})` }}
        ></div>
        <div className="Content Body-Regular-1">
          <h3>{price.toLocaleString().replace(',', ' ')} ₽</h3>
          <p className="EstateTile-Description">
            {title}, {totalSpace ? totalSpace.value : ''} м2
          </p>
          <p className="EstateTile-Address Body-Regular-2">
            <Icon name="map-marker-alt" weight="solid" />
            {address.formattedAddress}
          </p>
        </div>
      </div>
    </NavLink>
  )
}

export default EstateTile
