import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './NovostroyTile.sass'

function NovostroyTile({ avatar, title, counter, address, price, startFrom }) {
  return (
    <div className="NovostroyTile">
      <div
        className="Image"
        style={{ backgroundImage: `url(${avatar})` }}
      ></div>
      <div className="Content Body-Regular-1">
        <h3>{title}</h3>
        <p className="EstateTile-Description">
          {counter} квартира от застройщика
        </p>
        <p className="EstateTile-Address Body-Regular-2">
          <Icon name="map-marker-alt" weight="solid" />
          {address}
        </p>
        <p className="EstateTile-Address Body-Regular-2">
          <Icon name="check-square" weight="regular" />
          Есть сданные
        </p>
        <ul className="EstateParamsData">
          {price.map((p) => (
            <li className="ParamData">
              <span className="ParamTitle">{p.label}</span>
              <span className="ParamDevider">&nbsp;</span>
              <span className="ParamValue">от {p.value} млн. ₽</span>
            </li>
          ))}
        </ul>
        <h3 className="StartFromPrice">от {startFrom} ₽</h3>
      </div>
    </div>
  )
}

export default NovostroyTile
