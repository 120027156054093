import React from 'react'
import './EstateCarousel.sass'

function EstateCarousel({
  title,
  estateCarouselButtons,
  tiles,
  afterCarouselButton,
}) {
  return (
    <div className="EstateCarousel">
      <div className="DefaultContainer">
        <h2>{title}</h2>
        {estateCarouselButtons}
        <div className="EstateCarousel-Tiles">{tiles}</div>
        <div className="EstateCarousel-Buttons">{afterCarouselButton}</div>
      </div>
    </div>
  )
}

export default EstateCarousel
