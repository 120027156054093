import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../App'
import { functions } from '../../config/firebase'
import Button from '../../ui/Button/Button'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../utils/forms/createForm'
import getFormValues from '../../utils/forms/getFormValues'
import FormRender from '../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../utils/forms/render/getFieldRenderObject'
import isFormValid from '../../utils/forms/validation/isFormValid'
import './RequestConsultation.sass'
import pattern from './assets/consultPattern.svg'
import sendTgMessage from '../../utils/sendTgMessage'
import getOnlyNumbers from '../../utils/forms/getOnlyNumbers'

function RequestConsultation() {
  const { city } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: new ConsultForm(),
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)

    try {
      window.ym(96308489, 'reachGoal', 'request_' + city.id)
    } catch (e) {
      console.log(e)
    }

    const values = getFormValues({ form })

    // const func = functions.httpsCallable('emailSender')

    // sendSms({ message: text })

    // func({
    //   subject: 'Запрос консультации',
    //   to: 'iseeblog1@gmail.com',
    //   html: text,
    // })

    sendTgMessage({
      telegramChatId: '-1002004320977',
      threadId: 18,
      msg: `
📞 Запрос консультации
Имя: ${values.userName}
Номер телефона: ${values.phone.value}
      `,
    }).then((result) => {
      if (result.data.ok) {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Мы получили вашу заявку. Наши специалисты свяжутся с вами в ближайшее время',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ConsultForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="RequestConsultation">
      <div
        className="DefaultSection Pattern"
        style={{ backgroundImage: `url(${pattern})` }}
      >
        <h1>Получите бесплатную консультацию</h1>
        <p>
          Мы рады ответить на любые вопросы. Оставьте заявку на консультацию или
          позвоните по номеру:{' '}
          <a href={`tel:+${getOnlyNumbers(city.contacts.phone)}`}>
            {city.contacts.phone}
          </a>
        </p>
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="RequestConsultation-Form">
          <FormRender
            sections={[{ fields: ['userName', 'phone'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <Button
            theme="primary"
            className="Button_theme_primary_color_black"
            title="Оставить заявку"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </div>
        <p className="Caption-Medium">
          Отправляя заявку вы соглашаетесь с нашей политикой обработки
          персональных данных
        </p>
      </div>
    </div>
  )
}

class ConsultForm {
  constructor() {
    this.userName = {
      field: {
        fieldId: 'userName',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        placeholder: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default RequestConsultation
