import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDC7ZraVZA8CkG4xg4itrCd9kxoBkP0SVg',
  authDomain: 'estate-cdbx-io.firebaseapp.com',
  databaseURL:
    'https://estate-cdbx-io-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'estate-cdbx-io',
  storageBucket: 'estate-cdbx-io.appspot.com',
  messagingSenderId: '967459993856',
  appId: '1:967459993856:web:66c2ab79d9b5da2e059be5',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
