import React, { useContext } from 'react'
import About from '../../../Edu/components/About/About'
import { AuthContext } from '../../../../App'
import voron from './voron.png'
import stolbov from './stolbov.png'
import './VikupAbout.sass'

function VikupAbout() {
  const { city } = useContext(AuthContext)

  let text = null
  let img = null

  if (city.id === 'murmansk') {
    img = stolbov
    text = (
      <>
        <p>Здравствуйте!</p>

        <p>
          Меня зовут Столбов Василий Эдуардович. Я руководитель отдела «Срочный
          выкуп» в компании «Мурманский Центр Управления Недвижимостью» и рад
          Вас приветствовать на нашем сайте.
        </p>
        <p>
          С 2015 года наше агентство работает на рынке недвижимости г. Мурманска
          и является одним из лидеров в регионе. А общий опыт работы в сфере
          недвижимости состовляет более 15 лет.
        </p>
        <p>
          Одно из направлений которым мы активно занимаемся — это Выкуп
          недвижимости. За нашими плечами огромный опыт успешно проведенных
          сделок и сотни довольных клиентов.
        </p>
        <p>
          Выкупаем залоговые квартиры (ипотека), спорные объекты, доли, дома и
          земельные участки.
        </p>
        <p>
          В первую очередь наши клиенты получают: безопасность, удобные и
          выгодные условия, комфорт, правовую и юридическую поддержку,
          конфиденциальность, наличный и безналичный расчет.
        </p>
        <p>
          Каждая сделка по-своему уникальна. Мы готовы предложить Вам гибкость и
          индивидуальный подход.
        </p>
        <p>Обращайтесь я всегда на связи и готов помочь!</p>
      </>
    )
  } else {
    img = voron
    text = (
      <>
        <p>Здравствуйте!</p>
        <p>
          Меня зовут Воронюк Владимир Сергеевич. Являюсь директором компании
          Мурманский центр управления недвижимостью и рад вас приветствовать на
          нашем сайте!
        </p>
        <p>
          МЦУН — команда профессионалов с общим опытом работы более 15 лет.
          Агентство работает на рынке недвижимости Мурманска с 2015 года, также
          с 2021 года открыт филиал в Воронеже.
        </p>
        <p>
          Свою карьеру с сфере недвижимости начал с 2003 года, имею юридическое
          образование.Готов проконсультировать Вас по любым вопросам.
        </p>
        <p>
          Одно из направлений деятельности — это выкуп недвижимости, а также
          спорные объекты, доли, дома и земельные участки. В том числе продажа и
          подбор квартир в новостройках и уже существующих жилых комплексах.
        </p>
        <p>
          <b>Наши клиенты получают:</b>
        </p>
        <p>
          → безопасность в подборе недвижимости и защиту от мошеннических
          действий;
          <br />→ комфорт в выборе той жилой площади, которая подходит именно
          вам; <br />
          → конфиденциальность;
          <br />
          → правовая и юридическая поддержка;
          <br />→ возможность наличного или безналичного расчёта.
        </p>
        <p>
          Готов предложить вам свои услуги по подбору недвижимости в Воронеже,
          столице Центрально-Черноземного региона России и в Мурманске.
        </p>
        <p>
          <b>
            Индивидуальный подход и комфортные условия для каждого клиента
            гарантируются!
          </b>
        </p>
      </>
    )
  }

  return (
    <div className="VikupAbout">
      <About title="Агентство недвижимости МЦУН" text={text} img={img} />
    </div>
  )
}

export default VikupAbout
