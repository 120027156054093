const cities = [
  {
    id: 'murmansk',
    title: 'Мурманск',
    contacts: {
      phone: '+7 (8152) 55-88-88',
      email: 'mcun51@mail.ru',
    },
    isDefault: true,
  },
  {
    id: 'voronezh',
    title: 'Воронеж',
    contacts: {
      phone: '+7 (903) 850-55-11',
      email: 'mcun51@mail.ru',
    },
    isDefault: true,
  },
]

export default cities
