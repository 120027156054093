import React from 'react'
import './WhyWe.sass'
import i1 from './trophy.png'
import i2 from './role.png'
import i3 from './price.png'
import i4 from './client.png'

function WhyWe() {
  const data = [
    {
      title: 'с 2014 года на рынке недвижимости',
      benefitText: `${new Date().getFullYear() - 2014} лет опыта`,
      description: 'работы на рынке недвижимости',
      icon: i1,
    },
    {
      title: 'Команда настоящих профессионалов',
      benefitText: 'более 20 риэлторов',
      description: 'с высшим юридическим образованием',
      icon: i2,
    },
    {
      title: 'Предложение лучшей цены на рынке',
      benefitText: 'выкупаем на 5% дороже',
      description: 'чем конкуренты',
      icon: i3,
    },
    {
      title: 'Индивидуальный подход к клиенту',
      benefitText: 'поможем решить',
      description: 'даже самые сложные вопросы',
      icon: i4,
    },
  ]
  return (
    <div className="WhyWe">
      <div className="DefaultContainer">
        <h2 className="Title">Почему с нами лучше?</h2>
        <div className="WhyWe-Container">
          {data.map((d) => (
            <WhyWeTile {...d} />
          ))}
        </div>
      </div>
    </div>
  )
}

function WhyWeTile({
  title = 'test',
  benefitText = 'benefit',
  description = 'description',
  icon,
}) {
  return (
    <div className="WhyWeTile">
      <div className="Box">
        <div className="IconContainer">
          <img src={icon} alt={title} />
        </div>
        <h4 className="Title">{title}</h4>
      </div>
      <h3 className="BenefitText">{benefitText}</h3>
      <p className="BenefitDescription Body-Regular-1">{description}</p>
    </div>
  )
}

export default WhyWe
