import React, { useContext, useEffect, useState } from 'react'
import './Calc.sass'
import { NavHashLink } from 'react-router-hash-link'
import Button from '../../../../../ui/Button/Button'
import scrollWithOffset from '../../../../../ui/ScrollToTop/scrollWithOffset'
import { createForm } from '../../../../../utils/forms/createForm'
import FormRender from '../../../../../utils/forms/render/FormRender'
import getFieldRenderObject from '../../../../../utils/forms/render/getFieldRenderObject'
import calcPattern from './assets/calcPattern.svg'
import trianglePattern from './assets/trianglePattern.svg'
import StatusMessage, {
  useStatusMessage,
} from '../../../../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../../../utils/forms/getFormValues'
import publicIp from 'public-ip'
import { functions } from '../../../../../config/firebase'
import sendTgMessage from '../../../../../utils/sendTgMessage'
import { AuthContext } from '../../../../../App'

function Calc() {
  const [form, setForm] = useState(createForm({ formPattern: new CalcForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const { city } = useContext(AuthContext)

  const onCalcSubmit = async (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    try {
      window.ym(96308489, 'reachGoal', 'request_' + city.id)
    } catch (e) {
      console.log(e)
    }

    const values = getFormValues({ form })

    // const func = functions.httpsCallable('emailSender')
    const ip = await publicIp.v4()
    const text = `
      Тип недвижимости: ${values.estateType}<br/>
      Адрес: ${values.address}<br/>
      Этаж: ${values.floor}<br/>
      Площадь: ${values.space}<br/>
      Предполагаемая дата продажи: ${values.date}<br/>
      Номер телефона: ${values.phone.value}<br/>
      Выезд специалиста: ${values.zamer ? 'Да' : 'Нет'}<br/>
      IP: ${ip}<br/>
    `

    // sendSms({ message: text })

    // func({
    //   subject: 'Запрос расчета стоимости недвижимости',
    //   to: 'iseeblog1@gmail.com',
    //   html: text,
    // })

    sendTgMessage({
      telegramChatId: '-1002004320977',
      threadId: 6,
      msg: `
‼️ Запрос расчета стоимости недвижимости

Тип недвижимости: ${values.estateType}
Адрес: ${values.address}
Этаж: ${values.floor}
Площадь: ${values.space}
Предполагаемая дата продажи: ${values.date}
Номер телефона: ${values.phone.value}
Выезд специалиста: ${values.zamer ? 'Да' : 'Нет'}
IP: ${ip}
      `,
    }).then((result) => {
      if (result.data.ok) {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Спасибо за предоставленные данные. В скором времени вы получите результаты расчетов!',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new CalcForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div
      id="calc"
      className="Calc"
      style={{
        backgroundImage: `url(${calcPattern}), url(${trianglePattern})`,
      }}
    >
      <h2>Предварительная оценка стоимости online</h2>
      <p className="Body-Regular-2">
        Узнайте стоимость вашей недвижимости online абсолютно бесплатно. Просто
        заполните форму представленную ниже.
      </p>
      <h4>Как это работает?</h4>
      <p className="Body-Regular-2">
        Для расчета стоимости наша система анализирует множество параметров,
        связанных с Вашей недвижимостью. В связи с этим результат расчета мы
        вышлем по SMS
      </p>
      <div className="Form">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[
            {
              fields: [
                'estateType',
                'address',
                'floor',
                'space',
                'date',
                'phone',
                'zamer',
              ],
            },
          ]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="Buttons">
          <NavHashLink
            to="/#calc"
            smooth
            activeClassName="Site-NavLink_theme_active"
            scroll={(el) => scrollWithOffset(el, -64)}
          >
            <Button
              title="Рассчитать стоимость"
              theme="primary"
              icon="calculator-alt"
              iconWeight="solid"
              size={48}
              className="Button_theme_primary_color_black"
              onClick={onCalcSubmit}
              isLoading={isLoading}
            />
          </NavHashLink>
        </div>
        <p className="Caption-Regular Description">
          Нажимая накнопку “Рассчитать стоимость”, я принимаю условия
          Пользовательского соглашения и даю согласие на обработку своих
          персональных данных.
        </p>
      </div>
    </div>
  )
}

class CalcForm {
  constructor() {
    this.estateType = {
      field: {
        fieldId: 'estateType',
        fieldType: 'input',
        inputType: 'text',
        label: 'Тип недвижимости',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.address = {
      field: {
        fieldId: 'address',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес обьекта',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.floor = {
      field: {
        fieldId: 'floor',
        fieldType: 'input',
        inputType: 'number',
        label: 'Укажите этаж',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.space = {
      field: {
        fieldId: 'space',
        fieldType: 'input',
        inputType: 'text',
        label: 'Укажите площадь',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.date = {
      field: {
        fieldId: 'date',
        fieldType: 'select',
        label: 'Предполагаемая дата продажи',
        getOptions: [
          { label: 'Срочно', value: 'Срочно' },
          { label: '1-3 мес.', value: '1-3 мес.' },
          { label: '3-6 мес.', value: '3-6 мес.' },
          { label: 'Более 6 мес.', value: 'Более 6 мес.' },
        ],
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.zamer = {
      field: {
        fieldId: 'zamer',
        fieldType: 'checkbox',
        required: false,
        label: 'Заказать бесплатный выезд специалиста по недвижимости',
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default Calc
