import React, { useState } from 'react'
import { useEffect } from 'react'
import getDoc from '../../../../../utils/db/getDoc'
import RieltorTile from '../../../../../ui/RieltorTile/RieltorTile'
import { isEmpty, toNumber } from 'lodash'
import promiseAllValues from '../../../../../utils/promiseAllValues'
import getCollection from '../../../../../utils/db/getCollection'

function RieltorsBlock() {
  const [rieltors, setRieltors] = useState(null)
  console.log(
    '🚀 ~ file: RieltorsBlock.jsx:11 ~ RieltorsBlock ~ rieltors:',
    rieltors
  )

  useEffect(() => {
    getDoc({ path: 'accounts', docId: 'mcun' }).then((result) => {
      setRieltors(
        result.members.filter((m) => m.roleId !== 'maHwdJZcZ5QQ5wfJLf0N')
      )
    })
  }, [])

  return (
    <div className="RieltorsBlock">
      <h2>Наша команда</h2>
      <div className="RieltorsBlock-Container">
        {!isEmpty(rieltors) &&
          rieltors.map((r) => <RieltorTile rieltorId={r.userId} />)}
      </div>
    </div>
  )
}

export default RieltorsBlock
