import React from 'react'
import AdBanner from '../../components/AdBanner/AdBanner'
import EstateCarousel from '../../components/EstateCarousel/EstateCarousel'
import EstateTile from '../../components/EstateTile/EstateTile'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import Top from '../../components/Top/Top'
import Button from '../../ui/Button/Button'
import VikupBenefits from './components/VikupBenefits/VikupBenefits'
import VikupRequest from './components/VikupRequest/VikupRequest'
import './Vikup.sass'
import bg from './vikupHeader.svg'
import VikupAbout from './components/VikupAbout/VikupAbout'

function Vikup() {
  return (
    <div className="Vikup">
      <Top />
      <Nav />
      <div className="Page-SectionsContainer">
        <Header
          className={'Vikup-Header'}
          backgroundSrc={bg}
          title={
            <h1 className="Vikup-Offer-Title">
              Найдем покупателя на вашу недвижимость или купим её сами
            </h1>
          }
          description={
            <h3 className="Vikup-Offer-Description">
              В среднем на 10% дороже конкуретов
            </h3>
          }
          buttons={
            <>
              <Button
                theme="primary"
                title="Оценить недвижимость онлайн"
                type="navhashlink"
                path="#calc"
              />
            </>
          }
        />
        <VikupRequest />
        <VikupBenefits />
        <VikupAbout />
        <RequestConsultation />
        {/* <FeedsCarousel className="VikupFeeds" /> */}
        {/* <AdBanner /> */}
        <Footer />
      </div>
    </div>
  )
}

export default Vikup
