import React from 'react'
import './Stepper.sass'

function Stepper() {
  const steps = [
    'Запишитесь на обучение',
    'Подтвердите заявку',
    'Пройдите собеседование',
    'Завершите курс обучения',
    'Станьте крутым специалистом',
  ]
  return (
    <div className="Stepper">
      <div className="Stepper-Container DefaultContainer">
        <h2>5 шагов к вашему карьерному развитию</h2>

        <div className="Stepper-TilesContainer">
          {steps.map((s, i) => (
            <StepTile title={s} index={i + 1} />
          ))}
        </div>
      </div>
    </div>
  )
}

function StepTile({ title, index }) {
  return (
    <div className="StepTile">
      <div className="StepIndex">{index}</div>
      <p className="StepLine"></p>
      <p className="Title Body-Regular-1">{title}</p>
    </div>
  )
}

export default Stepper
