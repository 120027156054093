import React, { useContext, useState } from 'react'
import './EstateBase.sass'
import Top from '../../components/Top/Top'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import RieltorTile from '../../ui/RieltorTile/RieltorTile'
import Button from '../../ui/Button/Button'
import ImageCarousel from '../EstateObject/components/ImageCarousel/ImageCarousel'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import promiseAllValues from '../../utils/promiseAllValues'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty } from 'lodash'
import parse from 'html-react-parser'
import EstateParamsData from '../EstateObject/components/ImageCarousel/EstateParamsData/EstateParamsData'
import getDoc from '../../utils/db/getDoc'
import { AuthContext } from '../../App'
import bg from './novostroyHeader.svg'

function EstateBase() {
  const [estate, setEstate] = useState(null)
  const { city, setCity } = useContext(AuthContext)

  useEffect(() => {
    promiseAllValues({
      estate: getCollection({
        path: 'accounts/mcun/estate',
        docIdName: 'estateId',
        whereQueries: [
          {
            fieldPath: 'operation',
            op: '==',
            value: 'sell',
          },
          { fieldPath: 'isDeleted', op: '==', value: false },
        ],
      }),
      params: getCollection({
        path: 'accounts/mcun/config/estate/params',
        docIdName: 'paramId',
      }),
      estateTypes: getCollection({
        path: 'accounts/mcun/config/estate/types',
        docIdName: 'typeId',
      }),
      rieltors: getDoc({ path: 'accounts', docId: 'mcun' }),
    }).then((result) => {
      setEstate(result)
    })
  }, [])

  return (
    <div>
      <Top />
      <Nav />

      <div className="EstateBase">
        <div className="DefaultContainer">
          {city.id === 'voronezh' && (
            <div
              className="NovostroyBanner"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="Content">
                <p className="Title">Все новостройки в Воронеже</p>
                <p className="Description">
                  Получите подборку актуальных предложений от застройщиков
                  бесплатно!
                </p>
                <Button
                  theme="primary"
                  title="Получить подборку квартир"
                  type="navhashlink"
                  path="/novostrojki"
                />
              </div>
            </div>
          )}
          {!isEmpty(estate) && (
            <div className="EstateBase-Container">
              <div className="EstateBase-Header">
                <h2>Каталог недвижимости</h2>

                <p>{estate.estate.length} объектов в базе</p>
              </div>
              {estate.estate.map((e) => (
                <EstateBaseTile
                  {...e}
                  estateTypes={estate.estateTypes}
                  paramsData={estate.params}
                  rieltors={estate.rieltors}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

function EstateBaseTile({
  estateId,
  address,
  photos,
  params,
  estateType,
  estateTypes,
  responsible,
  price,
  paramsData,
  rieltors,
}) {
  const description = find(params, ['paramId', 'ewVN5IRz0LYO0eB41YNJ'])
  const rooms = find(params, ['paramId', 'YCi3Yr0CmxCeRb7qHIvz'])
  const type = find(estateTypes, ['typeId', estateType])
  const space = find(params, ['paramId', 'DvN83asYpGTY06ZMfIHN'])

  const title =
    estateType === 'flat' ? `${type.label} (${rooms.value}-к)` : type.label

  const rieltorData = find(rieltors.members, ['userId', responsible])

  return (
    <div>
      <div className="EstateBaseTile">
        <NavLink to={`/catalog-nedvizhimosti/${estateId}`}>
          <div className="PhotoBlock">
            <ImageCarousel
              photos={photos}
              className={`B${estateId}`}
              thumbnails={3}
            />
          </div>
        </NavLink>
        <NavLink
          to={`/catalog-nedvizhimosti/${estateId}`}
          className="DataBlock"
        >
          <div className="BaseTitle">
            <h2>
              {title}, {space ? space.value : '0'}м2
            </h2>
            <div className="Controlls">
              <h2 className="Price">
                {price.toLocaleString().replace(',', ' ')} ₽
              </h2>
              {/* <Button
                theme="secondary"
                icon="heart"
                iconWeight="solid"
                path="#calc"
              /> */}
            </div>
          </div>
          <p className="Address">{address.formattedAddress}</p>
          <ul className="ParamBlock">
            <h4 className="Title">Об объекте</h4>
            <EstateParamsData paramsData={paramsData} estateData={{ params }} />
          </ul>
          {!isEmpty(description) && (
            <div className="DescriptionBlock">
              <h4>Описание</h4>
              <p className="Description">{parse(description.value)}</p>
            </div>
          )}
        </NavLink>
        <span></span>
        <div className="ActionBlock">
          <RieltorTile rieltorId={responsible} />
          <Button
            theme="primary"
            title="Заказать звонок"
            type="link"
            icon="phone-alt"
            iconWeight="solid"
            path={`tel: ${
              rieltorData ? rieltorData.phone.metadata.formatInternational : ''
            }`}
            // path="#calc"
          />
        </div>
      </div>
    </div>
  )
}

export default EstateBase
