import React from 'react'
import Button from '../../../../ui/Button/Button'
import './ToRentRequest.sass'
import img1 from './assets/img1.svg'
import img2 from './assets/img2.svg'
import img3 from './assets/img3.svg'
import img4 from './assets/img4.svg'
import img5 from './assets/img5.svg'
import img6 from './assets/img6.svg'
import OnlineAd from './OnlineAd/OnlineAd'
import InfoLine from '../../../../ui/InfoLine/InfoLine'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'

function ToRentRequest() {
  const offerData = [
    { title: `Полное консультирование в части подготовки квартиры к рекламе` },
    { title: `Размещение объекта во всех популярных базах данных` },
    { title: `Согласование условий аренды` },
    {
      title: `Мы предусматриваем формы залога, а также оплату первого и последнего месяцов аренды`,
    },
    { title: `Регистрация договора аренды при необходимости` },
    {
      title: `Производство фотоматериала квартиры для многоразового использования`,
    },
    { title: `Поиск арендатора` },
    { title: `Демонстрация и презентация квартиры` },
    { title: `Подписание договора аренды` },
    { title: `Передача квартиры по акту` },
  ]

  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div id="podat-obyavlenie-online" className="ToRentRequest">
      <div className="ToRentRequest-Container DefaultContainer">
        <h2>Как сдать квартиру в Мурманске?</h2>
        <div className="ToRentRequest-FormContainer">
          <div className="TextForm">
            <h2>Подать объявление по телефону</h2>
            <p>
              Экономьте своё время, силы и деньги! Наши специалисты в кратчайшие
              сроки свяжутся с Вами и разместят объявление у себя на сайте и тд.
            </p>
            <Button
              theme="primary"
              title="Заказать звонок специалиста"
              size={48}
              icon="phone"
              iconPosition="left"
              iconWeight="solid"
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Подать объявление по телефону',
                  emailSubject: '🏢 Запрос на сдачу недвижимости в аренду',
                })
              }
            />
            <h2 style={{ marginTop: '24px' }}>Что мы предлагаем?</h2>
            <div className="ToRent-Offer">
              {offerData.map((o) => (
                <InfoLine icon="check" {...o} />
              ))}
            </div>
          </div>
          <OnlineAd />
        </div>
      </div>
    </div>
  )
}

export default ToRentRequest
