import React from 'react'
import './Services.sass'

function Services({ title, data }) {
  return (
    <div className="Services">
      <div className="DefaultContainer">
        <h2>{title}</h2>
        <div className="Services-List">
          {data.map((d) => (
            <ServiceTile {...d} />
          ))}
        </div>
      </div>
    </div>
  )
}

function ServiceTile({
  title = 'Title',
  description = 'Description',
  icon,
  bgColor,
  onClick = () => null,
}) {
  return (
    <div
      className="ServiceTile"
      style={{ backgroundColor: bgColor }}
      onClick={onClick}
    >
      <div className="Content">
        <h3 className="Title">{title}</h3>
        <p className="Body-Regular-1">{description}</p>
      </div>
      <div className="ServiceTile-IconContainer">
        <img className="ServiceIcon" src={icon} alt={title} />
      </div>
    </div>
  )
}

export default Services
