import React, { useContext, useState } from 'react'
import './ContactUsForm.sass'
import { Link } from 'react-router-dom'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../../utils/forms/render/FormRender'
import isFormValid from '../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../utils/forms/getFormValues'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { functions } from '../../../config/firebase'
import sendTgMessage from '../../../utils/sendTgMessage'
import { AuthContext } from '../../../App'

function ContactUsForm({
  subject = 'Обратная связь с сайта',
  additionalContent = null,
  messageId = 18,
}) {
  const [form, setForm] = useState(
    createForm({ formPattern: new ContactForm() })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const { city } = useContext(AuthContext)

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    try {
      window.ym(96308489, 'reachGoal', 'request_' + city.id)
    } catch (e) {
      console.log(e)
    }

    const values = getFormValues({ form })

    sendTgMessage({
      telegramChatId: '-1002004320977',
      threadId: messageId,
      msg: `
${subject}

Имя: ${values.name}
E-mail: ${values.email}
Номер телефона: ${values.phone.value}
Комментарий: ${values.comment}
${additionalContent ? additionalContent : ''}
      `,
    }).then((result) => {
      if (result.data.ok) {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Спасибо за обращение. Наш менеджер свяжется с Вами в ближайшее время!',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ContactForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="ContactUs-Form">
      <h5>Свяжитесь с нами</h5>
      <form onSubmit={onSubmit}>
        <div className="Form-Fields">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <FormRender
            form={form}
            setForm={setForm}
            sections={[{ fields: ['name', 'email', 'phone', 'comment'] }]}
            errors={showErrors}
          />
          <p>
            Отправляя данную форму вы соглашаетесь с{' '}
            <Link to="/privacy-policy">политикой конфиденциальности</Link>
          </p>
        </div>
        <div className="ContactUsForm-Buttons">
          <Button
            title="Отправить сообщение"
            theme="solid"
            fill="accent"
            iconWeight="solid"
            size={48}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  )
}

class ContactForm {
  constructor() {
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: 'Комментарий',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default ContactUsForm
