import React from 'react'
import './Home.sass'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import bg from './homeHeader.jpg'
import HomeSearch from '../../components/HomeSearch/HomeSearch'
import getCollection from '../../utils/db/getCollection'
import Top from '../../components/Top/Top'
import Nav from '../../components/Nav/Nav'
import Button from '../../ui/Button/Button'
import Services from './components/Services/Services'
import ToDos from './components/ToDos/ToDos'
import Offers from './components/Offers/Offers'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import WhyWe from './components/WhyWe/WhyWe'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import EstateOffer from './components/EstateOffer/EstateOffer'
import News from './components/News/News'
import SeoText from './components/SeoText/SeoText'
// import getCollection from '../../utils/db/getCollection'
// import { createForm } from '../../utils/forms/createForm'
// import UserObject from '../../components/Auth/SignUp/functions/userObject'
// import getFormValues from '../../utils/forms/getFormValues'
// import { omit } from 'lodash'
// import setDoc from '../../utils/db/setDoc'

function Home() {
  //Update old users script
  // const test = () => {
  //   getCollection({
  //     path: 'users',
  //     docIdName: 'userId',
  //   }).then((result) => {
  //     const newUsers = result
  //       .filter((u) => !u.version)
  //       .map((u) => {
  //         const form = createForm({
  //           formData: omit(u, ['avatar']),
  //           formPattern: new UserObject(),
  //         })
  //         return { userId: u.userId, ...getFormValues({ form }) }
  //       })

  //     Promise.all(
  //       newUsers.map((u) =>
  //         setDoc({ path: 'users', docId: u.userId, data: omit(u, ['userId']) })
  //       )
  //     )
  //       .then((result) => {
  //         console.log(
  //           '🚀 ~ file: Home.jsx ~ line 32 ~ Promise.all ~ result',
  //           result
  //         )
  //       })
  //       .catch((e) => {
  //         console.log('🚀 ~ file: Home.jsx ~ line 35 ~ Promise.all ~ e', e)
  //       })
  //   })
  // }

  // const test = () => {
  //   getCollection({
  //     path: 'users',
  //     docIdName: 'userId',
  //     whereQueries: [{ fieldPath: 'phone', op: '==', value: 380631702147 }],
  //   }).then((result) => {
  //     console.log('🚀 ~ file: Home.jsx ~ line 56 ~ test ~ result', result)
  //   })
  // }

  return (
    <div className="Home">
      <Top />
      <Nav />
      <div className="Page-SectionsContainer">
        <Header
          className={'Home-Header'}
          backgroundSrc={bg}
          title={
            <h1 className="Offer-Title">
              Найдём квартиру Вашей мечты и поможем продать недвижимость выгодно
            </h1>
          }
          description={
            <h3 className="Offer-Description">
              В среднем на 10% дороже конкуретов
            </h3>
          }
        />
        <Offers />
        <ToDos />
        <RequestConsultation />
        <WhyWe />
        <FeedsCarousel className="HomeFeeds" />
        <EstateOffer />
        <News />
        <SeoText />

        <Footer />
      </div>
    </div>
  )
}

export default Home
