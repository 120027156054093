import React from 'react'
import Logo from '../Logo/Logo'
import './Nav.sass'
import { NavLink, withRouter } from 'react-router-dom'
import SiteNavLink from './SiteNavLink'
import { useRef } from 'react'
import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'
import { useEffect } from 'react'
import Media from 'react-media'
import Hamburger from 'react-hamburger-menu'

function Nav({ ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <div
      className={['Nav', ...(pos > 0 ? ['Nav_theme_shadow'] : [])].join(' ')}
    >
      <div className="Nav-Container DefaultContainer">
        <Media
          query="(max-width: 576px)"
          render={() => (
            <span ref={hamRef}>
              <Hamburger
                isOpen={menuIsOpen}
                width={24}
                height={16}
                strokeWidth={2}
                className="Hamburger-Container"
                color="#303030"
                borderRadius={2}
                animationDuration={0.2}
              />
            </span>
          )}
        />
        <Logo />
        <Media
          query="(max-width: 576px)"
          render={() => <span className="PseudoBlock"></span>}
        />
        <div
          ref={navRef}
          className="Nav-Menu"
          style={menuIsOpen ? { display: 'grid' } : {}}
        >
          <SiteNavLink title="Главная" path="/#home" />
          <SiteNavLink title="Услуги" path="/#offers" />
          <SiteNavLink title="Сервисы" path="/#services" />
          <SiteNavLink
            title="Каталог недвижимости"
            path="/catalog-nedvizhimosti"
          />
          <SiteNavLink title="Учебный центр" path="/uchebnyj-centr" />
          <SiteNavLink title="Контакты" path="#contacts" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(Nav)
