import React, { useContext } from 'react'
import AdBanner from '../../components/AdBanner/AdBanner'
import EstateCarousel from '../../components/EstateCarousel/EstateCarousel'
import EstateTile from '../../components/EstateTile/EstateTile'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import Top from '../../components/Top/Top'
import Button from '../../ui/Button/Button'
import './Rent.sass'
import rentHeader from './rentHeader.jpg'
import { AuthContext } from '../../App'

function Rent() {
  const { setShowContactUs } = useContext(AuthContext)
  return (
    <div className="Rent">
      <Top />
      <Nav />
      <div className="Page-SectionsContainer">
        <Header
          backgroundSrc={rentHeader}
          title={
            <h1 className="Rent-Offer-Title">Снять квартиру в Мурманске</h1>
          }
          description={
            <h3 className="Rent-Offer-Description">
              Получите подборку актуальных предложений абослютно бесплатно!
            </h3>
          }
          buttons={
            <>
              <Button
                theme="primary"
                title="Получить подборку квартир"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Получить подборку квартир',
                    emailSubject:
                      '[Арендовать недвижимость] Новый запрос от пользователя сайта',
                  })
                }
              />
              <Button
                type="navlink"
                theme="primary"
                title="Каталог недвижимости"
                className="Button_theme_primary_color_black"
                path="/catalog-nedvizhimosti"
              />
            </>
          }
        />

        <EstateCarousel
          title={'Новые объявления с нашей базы недвижимости'}
          estateCarouselButtons={
            <div className="Rent-EstateCarouselButtons">
              <div className="Filters">
                <span className="Filter-Title Body-Medium-1">
                  Арендовать недвижимость:
                </span>
                <div className="FilterButtons">
                  <Button theme="primary" title="Новостройки" size={24} />
                  <Button theme="secondary" title="Жилая" size={24} />
                  <Button theme="secondary" title="Коммерческая" size={24} />
                </div>
              </div>
              <Button
                theme="internal-link"
                title="Все объекты"
                size={24}
                icon="long-arrow-right"
                iconPosition="right"
              />
            </div>
          }
          tiles={
            <>
              <EstateTile />
              <EstateTile />
              <EstateTile />
              <EstateTile />
            </>
          }
          afterCarouselButton={
            <Button
              theme="primary"
              title="Перейти в каталог недвижимости"
              size={48}
              type="navlink"
              path="/catalog-nedvizhimosti"
            />
          }
        />
        <RequestConsultation />
        <FeedsCarousel />
        {/* <AdBanner /> */}
        <Footer />
      </div>
    </div>
  )
}

export default Rent
