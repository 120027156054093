import React, { useState } from 'react'
import Services from '../Services/Services'
import estimateIcon from './assets/estimateIcon.png'
import consultIcon from './assets/consultIcon.png'
import leadIcon from './assets/leadIcon.png'
import exchangeIcon from './assets/exchangeIcon.png'
import mortgageIcon from './assets/mortgageIcon.png'
import VikupRequest from '../../../Vikup/components/VikupRequest/VikupRequest'
import PopUp from '../../../../ui/PopUp/PopUp'
import Calc from '../../../Vikup/components/VikupRequest/Calc/Calc'
import './ToDos.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'

function ToDos() {
  const [showCalc, setShowCalc] = useState(null)
  const { setShowContactUs } = useContext(AuthContext)

  const data = [
    {
      title: 'Оценить',
      description: 'Расчет стоимости в день обращения',
      icon: estimateIcon,
      bgColor: '#F9F9F9',
      onClick: () => setShowCalc(true),
    },
    {
      title: 'Юридическая консультация',
      description: 'Ответим на все Ваши вопросы',
      icon: consultIcon,
      bgColor: '#F9F9F9',
      onClick: () =>
        setShowContactUs({
          show: true,
          title: 'Подбор недвижимости в аренду',
          emailSubject: '👨‍🎓 Запрос на юридическую консультацию',
          messageId: 18,
        }),
    },
    {
      title: 'Сопровождение',
      description: 'Оформим сделку быстро, выгодно и безопасно',
      icon: leadIcon,
      bgColor: '#F9F9F9',
      onClick: () =>
        setShowContactUs({
          show: true,
          title: 'Сопровождение',
          emailSubject: '👋 Запрос на сопровождение',
          messageId: 18,
        }),
    },
    {
      title: 'Обмен, покупка в другом городе',
      description:
        'Подберём варианты в другом регионе, поможем с продажей в Вашем',
      icon: exchangeIcon,
      bgColor: '#F9F9F9',
      onClick: () =>
        setShowContactUs({
          show: true,
          title: 'Обмен, покупка в другом городе',
          emailSubject: '🔃 Запрос на обмен, покупку в другом городе',
          messageId: 18,
        }),
    },
    {
      title: 'Ипотека',
      description: 'Подадим заявки в банки-партнёры',
      icon: mortgageIcon,
      bgColor: '#F9F9F9',
      onClick: () =>
        setShowContactUs({
          show: true,
          title: 'Ипотека',
          emailSubject: '💵 Запрос на ипотеку',
          messageId: 18,
        }),
    },
  ]

  return (
    <div id="services" className="ToDos">
      {showCalc && (
        <PopUp
          // title="Калькулятор расчета стоимости недвижимости"
          show
          className={'CalcInPop'}
          close={() => setShowCalc(null)}
        >
          <Calc />
        </PopUp>
      )}
      <Services title="Сервисы" data={data} />
    </div>
  )
}

export default ToDos
