import React, { useState } from 'react'
import Icon from '../Icon/Icon'
import avatar from './avatar.png'
import './RieltorTile.sass'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import { useEffect } from 'react'
import promiseAllValues from '../../utils/promiseAllValues'
import getDoc from '../../utils/db/getDoc'
import getCollection from '../../utils/db/getCollection'
import spinner from '../Spinner/Spinner'
import Spinner from '../Spinner/Spinner'

function RieltorTile({ rieltorId }) {
  const [rieltor, setRieltor] = useState(null)

  useEffect(() => {
    promiseAllValues({
      rieltors: getDoc({ path: 'accounts', docId: 'mcun' }),
      feeds: getCollection({
        path: 'accounts/mcun/feeds',
        docIdName: 'feedId',
      }),
    }).then((result) => {
      const rieltorData = find(result.rieltors.members, ['userId', rieltorId])
      const rieltorFeeds = result.feeds.filter((f) => f.rieltor === rieltorId)
      rieltorData.feedCounter = rieltorFeeds.length
      const ratingMark = rieltorFeeds.reduce(function (a, b) {
        return a + toNumber(b['rating'])
      }, 0)
      rieltorData.rating =
        Math.round((ratingMark / rieltorFeeds.length) * 10) / 10
      setRieltor(rieltorData)
    })
  }, [])

  return !isEmpty(rieltor) ? (
    <div className="RieltorTile">
      <div className="Avatar">
        <img src={rieltor.avatar ? rieltor.avatar.publicUrl : null} alt="" />
      </div>
      <div className="Data">
        <p className="UserName">{`${rieltor.lastName} ${rieltor.name} ${rieltor.middleName}`}</p>
        <div className="Rating">
          <Icon name="star" weight="solid" />
          <span className="Caption-Medium">{rieltor.rating}</span>
          <span className="Caption-Medium FeedsCounter">
            {rieltor.feedCounter} отзывов
          </span>
        </div>
      </div>
    </div>
  ) : (
    <Spinner type="popup" />
  )
}

export default RieltorTile
