import React, { useEffect, createContext, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import { db, firebase } from './config/firebase'
import getDoc from './utils/db/getDoc'
import addDoc from './utils/db/addDoc'
import setDoc from './utils/db/setDoc'
import LocationNotificationPopUp from './ui/LocationNotificationPopUp/LocationNotificationPopUp'
import { find, isEmpty } from 'lodash'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import Rent from './pages/Rent/Rent'
import Vikup from './pages/Vikup/Vikup'
import ToRent from './pages/ToRent/ToRent'
import Edu from './pages/Edu/Edu'
import { defineDefaultCity } from './components/CityChooser/CityChooser'
import EstateObject from './pages/EstateObject/EstateObject'
import EstateBase from './pages/EstateBase/EstateBase'
import EstateWhishlist from './pages/EstateWhishlist/EstateWhishlist'
import NewsPage from './pages/NewsPage/NewsPage'
import Novostroy from './pages/Novostroy/Novostroy'

export const AuthContext = createContext(null)

const initDates = () => {
  if (localStorage.getItem('date')) {
    let dObject = JSON.parse(localStorage.getItem('date'))
    dObject.start = new Date(dObject.start)
    dObject.end = new Date(dObject.end)
    return dObject
  }
  return { start: null, end: null }
}

const AuthProvider = ({ children }) => {
  const [city, setCity] = useState(defineDefaultCity())
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })

  const [user, setUser] = useState(null)
  const [cart, setCart] = useState({ id: null, products: [] })
  const [showAuth, setShowAuth] = useState(false)
  const [category, setCategory] = useState({ id: null, title: null })
  const [location, setLocation] = useState({
    id: null,
    title: null,
    lat: null,
    lng: null,
  })
  const [dates, setDates] = useState(initDates())
  const [showLocationNotice, setShowLocationNotice] = useState(false)

  useEffect(() => {
    let dbUserListener = () => null
    let dbCartListener = () => null
    const userStateListener = firebase.auth().onAuthStateChanged((result) => {
      if (result && result.uid) {
        dbCartListener()
        dbUserListener = db
          .collection('users')
          .doc(result.uid)
          .onSnapshot((snap) => {
            const user = {
              uid: snap.id,
              ...snap.data(),
            }
            setUser(user)
          })

        if (localStorage.getItem('cart')) {
          getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
            (gCartData) => {
              if (!isEmpty(gCartData.products)) {
                setDoc({
                  path: 'carts',
                  docId: result.uid,
                  data: { products: gCartData.products },
                }).then(() =>
                  db
                    .collection('carts')
                    .doc(localStorage.getItem('cart'))
                    .delete()
                    .then(() => localStorage.removeItem('cart'))
                )
              }
            }
          )
        }

        dbCartListener = db
          .collection('carts')
          .doc(result.uid)
          .onSnapshot((snap) => {
            const uCart = {
              id: snap.id || null,
              products: [],
              ...snap.data(),
            }
            setCart(uCart)
          })
      } else {
        setUser(null)

        if (localStorage.getItem('cart')) {
          getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
            (result) => {
              if (result) {
                dbCartListener = db
                  .collection('carts')
                  .doc(localStorage.getItem('cart'))
                  .onSnapshot((snap) => {
                    const uCart = {
                      id: snap.id || null,
                      products: [],
                      ...snap.data(),
                    }
                    setCart(uCart)
                  })
              } else {
                addDoc({
                  path: 'carts',
                  data: { products: [], cartType: 'guest' },
                }).then((docId) => {
                  localStorage.setItem('cart', docId)
                  dbCartListener = db
                    .collection('carts')
                    .doc(docId)
                    .onSnapshot((snap) => {
                      const uCart = {
                        id: docId,
                        products: [],
                        ...snap.data(),
                      }
                      setCart(uCart)
                    })
                })
              }
            }
          )
        } else {
          addDoc({
            path: 'carts',
            data: { products: [], cartType: 'guest' },
          }).then((docId) => {
            localStorage.setItem('cart', docId)
            dbCartListener = db
              .collection('carts')
              .doc(docId)
              .onSnapshot((snap) => {
                const uCart = {
                  id: docId,
                  products: [],
                  ...snap.data(),
                }
                setCart(uCart)
              })
          })
        }
        dbUserListener()
      }
    })
    return () => {
      userStateListener()
      dbUserListener()
      setUser(null)
      setCart({ id: null, products: [] })
      dbCartListener()
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('category')) {
      getDoc({
        path: 'config/products/categories',
        docId: localStorage.getItem('category'),
        docIdName: 'id',
      }).then((c) => setCategory(c))
    }

    if (localStorage.getItem('location')) {
      getDoc({
        path: 'cities',
        docId: localStorage.getItem('location'),
        docIdName: 'id',
      }).then((l) => setLocation(l))
    }
  }, [])

  useEffect(() => {
    const product = cart.products[0]
    if (
      (product && location.id && product.locationId !== location.id) ||
      (product && !location.id)
    ) {
      setShowLocationNotice(true)
    } else {
      setShowLocationNotice(false)
    }
  }, [location, cart])

  return (
    <AuthContext.Provider
      value={{
        city,
        showContactUs,
        setShowContactUs,

        user,
        setCity,
        showAuth,
        setShowAuth,
        category,
        setCategory,
        location,
        setLocation,
        dates,
        setDates,
        cart,
      }}
    >
      {showLocationNotice && <LocationNotificationPopUp />}
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />

      {/* <Auth /> */}
      <Switch>
        <Route path="/" exact render={() => <Home />} />

        <Route path="/estate" exact render={() => <EstateBase />} />
        {/*  <Route
          path={['/catalog/:subCategoryId', '/catalog']}
          component={CatalogIndex}
        />
        <Route path="/checkout" component={Checkout} />
        <Route
          path="/payment/process"
          render={() => <PaymentProcess />}
        ></Route>
        <Route
          path="/payment/success"
          render={() => <PaymentStatusPage status="success" />}
        ></Route>
        <Route
          path="/payment/failed"
          render={() => <PaymentStatusPage status="failed" />}
        ></Route>
        <PrivateRoute path="/profile" component={Profile} redirectTo="/" />
        <Route path="/email" component={PasswordResetEmail} />
        <Route path="/password-recovery" component={PasswordRecoveryPage} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/faq" component={Faq} />
        <Route path="/:notFound" component={Page404} /> */}
        {/* <Route path="/buy" component={Buy} /> */}
        <Route
          path={'/catalog-nedvizhimosti'}
          exact
          render={() => <EstateBase />}
        />
        <Route path={'/whishlist'} exact render={() => <EstateWhishlist />} />
        <Route
          path={'/catalog-nedvizhimosti/:id'}
          exact
          render={() => <EstateObject />}
        />
        <Route path="/prodazha-nedvizhimosti" component={Vikup} />
        <Route path="/arenda-nedvizhimosti" component={Rent} />
        <Route path="/sdat-nedvizhimost" component={ToRent} />
        <Route path="/uchebnyj-centr" component={Edu} />
        <Route path="/novostrojki" component={Novostroy} />
        <Route path="/novosti/:id" component={NewsPage} />
      </Switch>
    </AuthProvider>
  )
}

export default App
