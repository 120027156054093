import React from 'react'
import './Header.sass'

function Header({ className, backgroundSrc, title, description, buttons }) {
  return (
    <div
      id="home"
      className={`Header ${className}`}
      style={{ backgroundImage: `url(${backgroundSrc})` }}
    >
      <div className="Header-Container DefaultContainer">
        <div className="Header-Offer">
          <div className="Offer-Text">
            {title}
            {description}
          </div>
          <div className="Header-Buttons">{buttons}</div>
        </div>
      </div>
    </div>
  )
}

export default Header
