import React, { useContext, useEffect, useState } from 'react'
import './News.sass'
import newsImg from './testimg.jpg'
import askMcun from './askMcun.jpg'
import Button from '../../../../ui/Button/Button'
import { AuthContext } from '../../../../App'
import getCollection from '../../../../utils/db/getCollection'
import { find, isEmpty, orderBy } from 'lodash'
import Spinner from '../../../../ui/Spinner/Spinner'
import { NavLink } from 'react-router-dom'
import { formatDateAsDayMontYearHHMM } from '../../../../utils/date/dateFormat'
import parse from 'html-react-parser'

function News() {
  const { setShowContactUs } = useContext(AuthContext)
  const [news, setNews] = useState(null)
  console.log('🚀 ~ file: News.jsx:14 ~ News ~ news:', news)

  useEffect(() => {
    getCollection({
      path: 'accounts/mcun/news',
      docIdName: 'newsId',
    }).then((data) => setNews(orderBy(data, ['created.at'], ['desc'])))
  }, [])

  return (
    <div className="News">
      <div className="DefaultContainer">
        <h2>Последние новости с рынка недвижимости</h2>
        <div className="News-Container">
          {!isEmpty(news) ? (
            <>
              <div className="TopNews">
                {news.slice(0, 3).map((n, i) => (
                  <NewsTile type={i === 0 ? 'BigTopType' : 'TopType'} {...n} />
                ))}
              </div>
              <div className="OtherNews">
                <h4>Другое</h4>
                {news.slice(3).map((n, i) => (
                  <NewsTile type={'DefaultType'} {...n} />
                ))}
              </div>
            </>
          ) : (
            <Spinner type="module" />
          )}
          {/* <div className="TopNews">
            <NewsTile type="BigTopType" />
            <NewsTile type="TopType" />
            <NewsTile type="TopType" />
          </div>
          <div className="OtherNews">
            <h4>Другие новости</h4>
            <NewsTile type="DefaultType" />
            <NewsTile type="DefaultType" />
            <NewsTile type="DefaultType" />
            <NewsTile type="DefaultType" />
          </div> */}
          <div className="AskBlock">
            <img className="AskImg" src={askMcun} alt="Спроси у МЦУН" />
            <h3>Спроси у МЦУН</h3>
            <p className="Body-Regular-1">
              Наши специалисты и дежурные агенты, с радостью ответят на любые
              нестандартные вопросы.
            </p>
            <Button
              theme="primary"
              title="Задать вопрос"
              type="button"
              icon="envelope"
              iconWeight="solid"
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Задать вопрос МЦУН',
                  emailSubject: '❓ Новый вопрос к МЦУН',
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function NewsTile({
  newsId,
  type,
  title,
  photos,
  shortNews = '',
  longNews = '',
  created,
}) {
  const avatar = find(photos, ['isAvatar', true])

  return (
    <div className={`NewsTile ${type}`}>
      <NavLink to={`/novosti/${newsId}`}>
        {avatar && (
          <div className="NewsTile-Image">
            <img src={avatar.publicUrl} alt="title" />
          </div>
        )}
        <div className="NewsTile-Content">
          <h3 className="Title">{title}</h3>
          <p className="Caption-Regular Date">
            {created && formatDateAsDayMontYearHHMM(created.at.seconds * 1000)}
          </p>
          <p className="Body-Regular-1 NewsText">{parse(shortNews)}</p>
        </div>
      </NavLink>
    </div>
  )
}

export default News
