import React from 'react'
import './About.sass'
import abImg from './about.png'

function About({ title = null, text = null, img = null }) {
  const aboutTitle = title ? title : 'Лучший бизнес тренер в Мурманской области'
  const aboutImg = img ? img : abImg
  const aboutText = text ? (
    text
  ) : (
    <>
      <p>
        Более 20 лет работаю в сфере недвижимости. Последние 8 лет занимаюсь
        обучением специалистов, многие мои ученики лидеры продаж ведущих
        компаний Мурманска, многие открыли свои агентства.
      </p>
      <p>
        C 2004 года прошел обучение в Санкт-Петербургском институте
        недвижимости. На протяжении всех лет постоянно обучаюсь. Прошел более 12
        тренингов и семинаров по профессиональной деятельности. Разработал ряд
        лекций: «Риэлторское дело от А до Я», «Холодные звонки как метод
        формирования клиентской базы», «Успешный риэлтор, кто он?» «Презентация,
        путь к успеху», «Доверительные отношения, в работе риэлтора» и другие.
        Внедрил и успешно практикую программу обучения «Быстрый СТАРТ».
      </p>
      <p>
        Мой метод - это индивидуальный подход к каждому стажеру. Я не штампую
        «ширпотреб», а стараюсь вырастить «жемчужину» и в этом главное отличие
        нашего учебного центра.
      </p>
      <p>
        Хотите стать той самой «жемчужиной», тогда Вам действительно к НАМ. Мы
        даем возможность в короткое время освоить профессию и стать частью нашей
        команды.
      </p>
    </>
  )

  return (
    <div className="About">
      <div className="About-Container DefaultContainer">
        <h2>{aboutTitle}</h2>
        <div className="About-Body">
          <div className="Image">
            <img src={aboutImg} alt={aboutTitle} />
          </div>
          <div className="Text">{aboutText}</div>
        </div>
      </div>
    </div>
  )
}

export default About
