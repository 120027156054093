import React, { useState, useRef, useEffect, useContext } from 'react'
import './DateChooser.sass'
import { usePopUp } from '../../../hooks/usePopUp'
import SearchInput from '../SearchInput/SearchInput'
import Button from '../../../ui/Button/Button'
import DatePicker from '../../../ui/DatePicker/DatePicker'
import { createForm } from '../../../utils/forms/createForm'
import getFieldRenderObject from '../../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../../utils/forms/render/FormRender'
import { AuthContext } from '../../../App'
import getFormattedValue from './functions/getFormattedValue'
import { differenceInCalendarDays } from 'date-fns'

function DateChooser({ onSubmit }) {
  const { category, location, dates, setDates } = useContext(AuthContext)
  const butRef = useRef()
  const ddRef = useRef()
  const [showPopUp] = usePopUp(butRef, ddRef)
  const [selectedDate, setSelectedDate] = useState(dates)

  const [form, setForm] = useState(
    createForm({
      formPattern: {
        sameDay: {
          field: {
            fieldId: 'sameDay',
            fieldType: 'checkbox',
            label: 'Заберу після 15:00 (-1 день оренди)',
          },
          render: getFieldRenderObject(),
        },
      },
      formData: {
        sameDay: localStorage.getItem('sameDay'),
      },
    })
  )

  useEffect(() => {
    if (selectedDate.start && selectedDate.end) {
      setDates(selectedDate)
    } else {
      setDates({ start: null, end: null })
    }
  }, [selectedDate])

  useEffect(() => {
    if (form.sameDay.value) {
      localStorage.setItem('sameDay', true)
    } else {
      localStorage.removeItem('sameDay')
    }
  }, [form.sameDay.value])

  const onDateChange = ([start, end]) => {
    setSelectedDate({ start, end })
  }

  return (
    <div className="DateChooser">
      <SearchInput
        ref={butRef}
        title="Термін прокату"
        placeholder="Коли будете там?"
        dropdownElements={
          showPopUp ? (
            <div ref={ddRef} className="Calendar">
              <DatePicker
                selected={selectedDate.start}
                startDate={selectedDate.start}
                endDate={selectedDate.end}
                selectsRange
                onChange={onDateChange}
                monthsShown={2}
                minDate={new Date()}
              />
              <div className="Buttons">
                {differenceInCalendarDays(
                  selectedDate.end,
                  selectedDate.start
                ) > 0 ? (
                  <FormRender
                    sections={[{ fields: ['sameDay'] }]}
                    form={form}
                    setForm={setForm}
                  />
                ) : (
                  <span />
                )}
                <div className="Buttons-Container">
                  <Button
                    title="Гнучкі дати"
                    theme="secondary"
                    size={40}
                    onClick={() => {
                      setDates({ start: null, end: null })
                      onSubmit(true)
                    }}
                    disabled={!category.id || !location.id}
                  />
                  <Button
                    title="Пошук"
                    icon="search"
                    theme="primary"
                    size={40}
                    onClick={onSubmit}
                    disabled={!category.id || !location.id}
                  />
                </div>
              </div>
            </div>
          ) : null
        }
        value={getFormattedValue({
          start: dates.start,
          end: dates.end,
          sameDay: form.sameDay.value,
        })}
        onValueDrop={() => {}}
        button={
          <Button
            theme="primary"
            size={40}
            icon="search"
            onClick={onSubmit}
            disabled={!category.id || !location.id}
          />
        }
      />
    </div>
  )
}

export default DateChooser
