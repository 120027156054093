import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../ui/Button/Button'
import getDoc from '../../utils/db/getDoc'
import SitePage from '../SitePage/SitePage'
import './NewsPage.sass'
import Spinner from '../../ui/Spinner/Spinner'
import { find, isEmpty } from 'lodash'
import { formatDateAsDayMontYearHHMM } from '../../utils/date/dateFormat'
import parse from 'html-react-parser'
import Top from '../../components/Top/Top'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'

function NewsPage({ ...router }) {
  const newsId = router.match.params.id
  const [newsData, setNewsData] = useState(null)

  useState(() => {
    if (newsId) {
      getDoc({
        path: 'accounts/mcun/news',
        docId: newsId,
        docIdName: 'newsId',
      }).then((result) => {
        if (result) setNewsData(result)
      })
    }
  }, [newsId])

  return (
    <div>
      <Top />
      <Nav />
      <div className="NewsPage">
        <div className="DefaultContainer">
          <div className="NewsPage-Container">
            {!isEmpty(newsData) ? (
              <>
                <p className="Date Caption-Medium">
                  {formatDateAsDayMontYearHHMM(
                    newsData.created.at.seconds * 1000
                  )}
                </p>
                <p className="Title">{newsData.title}</p>
                {find(newsData.photos, ['isAvatar', true]) && (
                  <img
                    className="NewsPage-Avatar"
                    src={find(newsData.photos, ['isAvatar', true]).publicUrl}
                    alt={newsData.title}
                  />
                )}

                <p>{parse(newsData.longNews)}</p>
              </>
            ) : (
              <Spinner type="module" />
            )}
          </div>
          <Button
            theme="primary-white"
            title="Все новости"
            type="navlink"
            icon="arrow-right"
            iconPosition="right"
            path="/#novosti"
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(NewsPage)
