import React, { useContext, useEffect, useState } from 'react'
import './EstateObject.sass'
import Top from '../../components/Top/Top'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import RieltorTile from '../../ui/RieltorTile/RieltorTile'
import Button from '../../ui/Button/Button'
import ImageCarousel from './components/ImageCarousel/ImageCarousel'
import map from './map.jpg'
import EstateCarousel from '../../components/EstateCarousel/EstateCarousel'
import EstateTile from '../../components/EstateTile/EstateTile'
import { withRouter } from 'react-router-dom'
import promiseAllValues from '../../utils/promiseAllValues'
import getDoc from '../../utils/db/getDoc'
import { find, isEmpty } from 'lodash'
import { Map, Placemark, YMaps } from 'react-yandex-maps'
import EstateParamsData from './components/ImageCarousel/EstateParamsData/EstateParamsData'
import getCollection from '../../utils/db/getCollection'
import { AuthContext } from '../../App'

function EstateObject({ ...router }) {
  const estateId = router.match.params.id
  const [estate, setEstate] = useState(null)
  const { setShowContactUs } = useContext(AuthContext)
  console.log('🚀 ~ file: EstateObject.jsx:23 ~ EstateObject ~ estate:', estate)

  useEffect(() => {
    promiseAllValues({
      estate: getDoc({
        path: 'accounts/mcun/estate',
        docId: estateId,
        docIdName: 'estateId',
      }),
      params: getCollection({
        path: 'accounts/mcun/config/estate/params',
        docIdName: 'paramId',
      }),
      estateTypes: getCollection({
        path: 'accounts/mcun/config/estate/types',
        docIdName: 'typeId',
      }),
      rieltors: getDoc({ path: 'accounts', docId: 'mcun' }),
    }).then((result) => {
      setEstate(result)
    })
  }, [])

  const description = !isEmpty(estate)
    ? find(estate.estate.params, ['paramId', 'ewVN5IRz0LYO0eB41YNJ'])
    : null

  const rooms = !isEmpty(estate)
    ? find(estate.estate.params, ['paramId', 'YCi3Yr0CmxCeRb7qHIvz'])
    : null

  const type = !isEmpty(estate)
    ? find(estate.estateTypes, ['typeId', estate.estate.estateType])
    : null

  const title =
    !isEmpty(type) &&
    !isEmpty(estate) &&
    (estate.estate.estateType === 'flat'
      ? `${type.label} (${rooms.value}-к)`
      : type.label)

  const totalSpace = !isEmpty(estate)
    ? find(estate.estate.params, ['paramId', 'DvN83asYpGTY06ZMfIHN'])
    : null

  const rieltorData = !isEmpty(estate)
    ? find(estate.rieltors.members, ['userId', estate.estate.responsible])
    : null

  return (
    <div>
      <Top />
      <Nav />
      {!isEmpty(estate) && (
        <div className="EstateObject">
          <div className="DefaultContainer">
            <div className="EstateObject-Container">
              <div className="EstateData">
                <ImageCarousel
                  thumbnails={6}
                  className={'EstateBaseCarousel'}
                  photos={estate.estate.photos}
                />
                <div className="EstateData-Container">
                  <h2>Об объекте</h2>
                  <EstateParamsData
                    paramsData={estate.params}
                    estateData={estate.estate}
                  />
                  <h2>Описание</h2>
                  <p className="Description">
                    {!isEmpty(description) && description.value}
                  </p>
                  <h2>Расположение</h2>
                  <YMaps>
                    <Map
                      className="Map"
                      defaultState={{
                        center: [
                          estate.estate.address.coordinates.lat,
                          estate.estate.address.coordinates.lng,
                        ],
                        zoom: 16,
                      }}
                    >
                      <Placemark
                        geometry={[
                          estate.estate.address.coordinates.lat,
                          estate.estate.address.coordinates.lng,
                        ]}
                        options={{ preset: 'islands#blueIcon' }}
                      />
                    </Map>
                  </YMaps>
                </div>
              </div>
              <div className="ActionBlock">
                <p className="Body-Regular-1">
                  {title}, {!isEmpty(totalSpace) && `${totalSpace.value} м2`}
                </p>
                <h2 className="Price">
                  {estate.estate.price.toLocaleString().replace(',', ' ')} ₽
                </h2>
                <p className="RequestPrice Caption-Regular">
                  или{' '}
                  <Button
                    theme="internal-link"
                    onClick={() =>
                      setShowContactUs({
                        show: true,
                        title: 'Предложить свою цену за объект',
                        emailSubject: '🏢 Вопрос по объекту',
                        additionalContent: `Ссылка на объект: https://mcun.ru/catalog-nedvizhimosti/${estateId}`,
                      })
                    }
                  >
                    предложить свою цену
                  </Button>
                </p>
                <RieltorTile rieltorId={estate.estate.responsible} />
                <Button
                  theme="primary"
                  title="Позвонить"
                  type="link"
                  icon="phone-alt"
                  iconWeight="solid"
                  path={`tel: ${
                    rieltorData
                      ? rieltorData.phone.metadata.formatInternational
                      : ''
                  }`}
                />
              </div>
            </div>
          </div>
          {/* <EstateCarousel
            title={'Новые объявления с нашей базы недвижимости'}
            estateCarouselButtons={
              <div className="Rent-EstateCarouselButtons">
                <div className="Filters">
                  <span className="Filter-Title Body-Medium-1">
                    Арендовать недвижимость:
                  </span>
                  <div className="FilterButtons">
                    <Button theme="primary" title="Новостройки" size={24} />
                    <Button theme="secondary" title="Жилая" size={24} />
                    <Button theme="secondary" title="Коммерческая" size={24} />
                  </div>
                </div>
                <Button
                  theme="internal-link"
                  title="Все объекты"
                  size={24}
                  icon="long-arrow-right"
                  iconPosition="right"
                />
              </div>
            }
            tiles={
              <>
                <EstateTile />
                <EstateTile />
                <EstateTile />
                <EstateTile />
              </>
            }
          /> */}
        </div>
      )}

      <Footer />
    </div>
  )
}

export default withRouter(EstateObject)
