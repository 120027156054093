import React, { useContext } from 'react'
import Services from '../Services/Services'
import buyIcon from './assets/buyIcon.png'
import sellIcon from './assets/sellIcon.png'
import fastSellIcon from './assets/fastSellIcon.png'
import rentIcon from './assets/rentIcon.png'
import rentOutIcon from './assets/rentOutIcon.png'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../../../App'

function Offers({ ...router }) {
  const { setShowContactUs } = useContext(AuthContext)
  const data = [
    {
      title: 'Купить',
      description: 'Проверим объект и собственника, минимизируем риски',
      icon: buyIcon,
      bgColor: '#F1FAFF',
      onClick: () => router.history.push('/catalog-nedvizhimosti'),
    },
    {
      title: 'Продать',
      description:
        'Рассчитаем стоимость Вашей недвижимости и поможем продать дороже',
      icon: sellIcon,
      bgColor: '#FFFADE',
      onClick: () => router.history.push('/prodazha-nedvizhimosti'),
    },
    {
      title: 'Быстро продать',
      description: 'Приобретём Вашу недвижимость быстро, выгодно и безопасно',
      icon: fastSellIcon,
      bgColor: '#FFEAD7',
      onClick: () => router.history.push('/prodazha-nedvizhimosti'),
    },
    {
      title: 'Снять',
      description: 'Подберём для Вас лучшее предложение',
      icon: rentIcon,
      bgColor: '#F6FFEA',
      onClick: () =>
        setShowContactUs({
          show: true,
          title: 'Подбор недвижимости в аренду',
          emailSubject: '🏢 Запрос на снятие в аренду',
          messageId: 18,
        }),
      // onClick: () => router.history.push('/arenda-nedvizhimosti'),
    },
    {
      title: 'Сдать',
      description: 'Найдем для Вас лучшего арендатора',
      icon: rentOutIcon,
      bgColor: '#F1F1FF',
      onClick: () => router.history.push('/sdat-nedvizhimost'),
    },
  ]

  return (
    <div id="offers" className="ToDos">
      <Services title="Услуги агентства" data={data} />
    </div>
  )
}

export default withRouter(Offers)
