import React from 'react'
import './SeoText.sass'
import photo from './seoPhoto.jpg'

function SeoText({ title = null, img = null, text = null }) {
  return (
    <div className="SeoText">
      <div className="DefaultContainer">
        <h2>
          {title ||
            `Агентство недвижимости “МЦУН” в Мурманске и Воронеже. Весь спектр
          услуг по работе с недвижимостью`}
        </h2>
        <div className="SeoText-Container">
          <img
            src={img || photo}
            alt="Агентство недвижимости “МЦУН” в Мурманске и Воронеже"
          />
          <div className="Text">
            {text ||
              `Агентство «Мурманский центр управления недвижимостью» в Мурманске и
            Воронеже: квартиры, доли, комнаты, участки, дома, коммерческая
            недвижимость. «Мурманский центр управления недвижимостью» -лидер
            риэлторских услуг в российском Заполярье. Агентство недвижимости в
            Мурманске проводит все операции с жилой, коммерческой недвижимостью
            и землёй: - помощь в купле-продаже квартир, домов, земельных
            участков и объектов нежилой недвижимости; -поиск арендаторов и
            арендодателей; - сопровождение сделок в органах Росреестра;
            -составление и печать договоров; -консультации по приватизации
            жилья; -подбор вариантов ипотеки и оформление договоров с банками;
            -срочный выкуп недвижимости за «живые» деньги. Агентство
            недвижимости собрало в своем каталоге практически все типы объектов
            недвижимости, которые можно найти в Мурманске и Мурманской области.
            У нас можно подобрать просторные квартиры в новостройках, а также
            бюджетное вторичное жильё, комфортабельные коттеджи и недорогие
            дачи. Мурманчане, нуждающиеся в отдельном жилье, но пока не имеющие
            финансовой возможности купить квартиру, смогут найти у нас квартиру
            для аренды недалеко от места работы. Те, кто располагает лишними
            квадратными метрами, всегда могут выгодно их сдать. Мы рады
            поддержать представителей бизнеса и приглашаем мурманских и
            иногородних предпринимателей обращаться в наше агентство
            недвижимости: Мурманская область и её столица всегда открыта для
            смелых начинаний и идей. У нас можно купить или снять торговые
            площади, производственные помещения, земельные участки под
            строительство и земли свободного назначения.`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeoText
