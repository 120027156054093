import React, { useState } from 'react'
import './Partners.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Carousel from '../../ui/Carousel/Carousel'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy } from 'lodash'
import p1 from './assets/p1.svg'
import p2 from './assets/p2.svg'
import p3 from './assets/p3.svg'
import p4 from './assets/p4.svg'
import p5 from './assets/p5.svg'
import p6 from './assets/p6.svg'

function Partners() {
  const partners = [p1, p2, p3, p4, p5, p6]

  return (
    <section id="partners" className="Partners">
      <div className="DefaultContainer">
        <h2>Наши партнеры</h2>
      </div>
      <div className="Partners-Container DefaultContainer">
        {!isEmpty(partners) && (
          <Carousel
            element="Partners-Carousel"
            options={{
              type: 'carousel',
              gap: 24,
              perView: 6,
              hoverpause: true,
              autoplay: 5000,
              useDots: true,
              useArrows: true,
              breakpoints: {
                992: { perView: 5 },
                768: { perView: 3 },
                576: { perView: 2 },
              },
            }}
          >
            {partners.map((p) => (
              <PartnerTile key={p} src={p} />
            ))}
          </Carousel>
        )}
      </div>
    </section>
  )
}

function PartnerTile({ src }) {
  return <img src={src} className="PartnerTile" />
}

export default Partners
