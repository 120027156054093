import React from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import Icon from '../../ui/Icon/Icon'
import CityChooser from '../CityChooser/CityChooser'
import Logo from '../Logo/Logo'
// import CookieNotification from '../CookieNotification/CookieNotification'
import './Footer.sass'

function Footer() {
  const { city } = useContext(AuthContext)
  return (
    <section id="contacts" className="Footer">
      <div className="Footer-Contacts-Container DefaultContainer">
        {/* <CookieNotification /> */}
        <div className="LogoLine">
          <Logo />
        </div>
        <div className="ContactLine">
          <div className="ContactBlock">
            {/* <CityChooser /> */}
            <Button
              theme="internal-link"
              size={40}
              icon="phone"
              iconWeight="solid"
              title={city.contacts.phone}
            />
            <Button
              theme="internal-link"
              size={40}
              icon="envelope"
              iconWeight="solid"
              title={city.contacts.email}
            />
          </div>
          <div className="CallUsBlock">
            <div>
              <span className="Body-Medium-2">Написать нам:</span>{' '}
              <a href="https://t.me/+79600203225" target="blank">
                <Icon name="telegram" weight="brand" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=79600203225"
                target="blank"
              >
                <Icon name="whatsapp" weight="brand" />
              </a>
              <a href="viber://chat?number=%2B79600203225" target="blank">
                <Icon name="viber" weight="brand" />
              </a>
            </div>
            <div>
              <span className="Body-Medium-2">Мы в соц. сетях:</span>{' '}
              <a href="https://vk.com/mcun51" target="blank">
                <Icon name="vk" weight="brand" />
              </a>
              <a href="https://www.instagram.com/mcun_murmansk/" target="blank">
                <Icon name="instagram" weight="brand" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="Footer-LinksContainer DefaultContainer">
        <FooterLinkBlock
          title="Услуги"
          icon="search-location"
          content={
            <>
              <Link to="/catalog-nedvizhimosti">Купить недвижимость</Link>
              <Link to="/prodazha-nedvizhimosti">Продать недвижимость</Link>
              <Link to="/prodazha-nedvizhimosti">
                Быстро продать недвижимость
              </Link>
              <Link to="/arenda-nedvizhimosti">Снять в аренду</Link>
              <Link to="/sdat-nedvizhimost">Сдать в аренду</Link>
            </>
          }
        />
        <FooterLinkBlock
          title="Сервисы"
          icon="th-list"
          content={
            <>
              <Link to="/#home">Оценить свою недвижимость</Link>
              <Link to="/#home">Юридическая консультация</Link>
              <Link to="/#home">Сопровождение</Link>
              <Link to="/#home">Обмен, покупка в другом городе</Link>
              <Link to="/#home">Ипотека</Link>
            </>
          }
        />
        {/* <FooterLinkBlock
          title="Каталог недвижимости"
          icon="th-list"
          // content={
          //   <>
          //     <Link to="/catalog-nedvizhimosti">Купить квартиру</Link>
          //     <Link to="/contacts">Квартиры</Link>
          //     <Link to="/contacts">Квартиры</Link>
          //   </>
          // }
        /> */}
        <FooterLinkBlock
          title="Агентство"
          icon="building"
          content={
            <>
              <Link to="/uchebnyj-centr">Учебный центр</Link>
            </>
          }
        />
        <FooterLinkBlock
          title="Контакты"
          icon="headset"
          content={
            <>
              <p>183038 г. Мурманск, пр. Тарана, д. 25</p>
              <p>г. Воронеж, пр. Московский, д. 124</p>
            </>
          }
        />
      </div>

      <div className="Copyrights Caption-Regular">
        <div className="Copyrights-Container DefaultContainer">
          {/* <p>Условия использования и Политика конфиденциальности</p> */}
          <p>© 2008 - {new Date().getFullYear()} МЦУН. Все права защищены.</p>
        </div>
      </div>
    </section>
  )
}

function FooterLinkBlock({ title, icon, content }) {
  return (
    <div className="Footer-LinkBlock">
      <p className="Title">
        <Icon name={icon} weight="solid" />
        <h3>{title}</h3>
      </p>
      <div className="Content Body-Regular-2">{content}</div>
    </div>
  )
}

export default Footer
