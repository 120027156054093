import React from 'react'
// import AdBanner from '../../components/AdBanner/AdBanner'
// import EstateCarousel from '../../components/EstateCarousel/EstateCarousel'
// import EstateTile from '../../components/EstateTile/EstateTile'
// import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Nav from '../../components/Nav/Nav'
// import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import Top from '../../components/Top/Top'
import Button from '../../ui/Button/Button'
import './Edu.sass'
import bg from './edu.jpg'
import CourseInfo from './components/CourseInfo/CourseInfo'
import EduBenefits from './components/EduBenefits/EduBenefits'
import Stepper from './components/Stepper/Stepper'
import Resume from './components/Resume/Resume'
import WhatULearn from './components/WhatULearn/WhatULearn'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import Internship from './components/Internship/Internship'
import EduCheckout from './components/EduCheckout/EduCheckout'
import About from './components/About/About'

function Edu() {
  const { setShowContactUs } = useContext(AuthContext)
  const onCallbackRequest = () =>
    setShowContactUs({
      show: true,
      title: 'Записаться на обучение',
      emailSubject: '👨‍🎓 Запись на обучение',
    })
  return (
    <div className="Edu">
      <Top />
      <Nav />
      <div className="Page-SectionsContainer">
        <Header
          className={'Edu-Header'}
          backgroundSrc={bg}
          title={
            <h1 className="Edu-Offer-Title">
              Стань профессионалом
              <br />с командой МЦУН
            </h1>
          }
          description={
            <h3 className="Edu-Offer-Description">
              Пройди индивидуальное обучение с лучшим бизнес тренером
            </h3>
          }
          buttons={
            <>
              <Button
                theme="primary"
                title="Записаться на обучение"
                onClick={onCallbackRequest}
              />
            </>
          }
        />
        <CourseInfo />
        <Internship />
        <About />
        <WhatULearn />
        <Stepper />
        <EduCheckout />
        <Resume />

        <Footer />
      </div>
    </div>
  )
}

export default Edu
